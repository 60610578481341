@import "./components/atoms/toggle-switch/toggle-switch.css";
@import "components/molecules/MealCard/meal-card.scss";
@import "components/molecules/MealCard/Meal/meal.scss";
@import "components/molecules/nutrition-fact-summary/nutrition-fact-summary.scss";
@import "components/molecules/Beverages/beverages.scss";
@import "components/molecules/Beverages/beverage-item/beverage-item.scss";
@import "components/molecules/Beverages/water-bar/water-bar.scss";
@import "./components/atoms/spinner/spinner.css";
@import "./components/atoms/action-link/action-link.css";
@import "components/molecules/MealCard/meal-card-header/meal-card-header.scss";
@import "./components/organisms/WeekView/WeekView.css";
@import "./components/organisms/WeekView/MealTable/MealTable.css";
@import "./components/organisms/WeekView/MealTable/MealItem/MealItem.css";
@import "./shared/shared.css";

@import "./variables.css";
@import "css/global.css";
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
body * {
	font-family: Poppins, sans-serif;
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
p {
	margin: 0;
	padding: 0;
	font-weight: 400;
}

::placeholder {
	/* Firefox, Chrome, Opera */
	color: #B0B4C4;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #B0B4C4;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #B0B4C4;
}

*:focus {
	outline: #353541;
}

.fontAwesome {
	font-family: 'Poppins', 'FontAwesome', sans-serif;
	font-size: "13px";
	line-height: "169%";
	margin-bottom: "5px";
}

.productsMeal ul {
	list-style: none;
	/* Remove list bullets */
	padding: 0;
	margin: 0;
}

.productsMeal li {
	padding-left: 0;
}

.productsMeal li:before {
	content: "•";
	/* Insert content that looks like bullets */
	padding-right: 8px;
	color: #FF7E36;
	/* Or a color you prefer */
	font-weight: bold;
	font-size: 22px;
}


@media screen and (min-width: 1180px) {
    .page {
        margin-left: 1em;
        margin-right: 1em;
        margin-bottom: 1em;
        margin-top:  1em;
    }
   
}

.header {
	font-weight: bold;
	font-size: 20px;
	line-height: 160.5%;
	letter-spacing: 0.02em;
	color: #353541;
}

input {
	border: 1px solid #B0B4C4;
	box-sizing: border-box;
	border-radius: 3px;
	height: 30px;
	width: 100px;
}

.disabled {
    cursor: auto;
	background: gray;
}

.divider {
	padding-left: 1.5px;
	padding-right: 1.5px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 170%;
	color: #8888;
	display: inline-block;
	width: inherit;
}

.paddingLeftRight20 {
	padding-left: 20px;
	padding-right: 20px;
}

.versionNumber {
	margin: 0 auto;
	width: auto;
	text-align: center;
	padding: 0;
	display: block;
	font-size: x-small;
}

/* after slider rollback */
/* todo: move to component in the future */
.scroll-wizzard {
	padding: var(--gutter) 0;
	display: grid;
	grid-gap: var(--gutter) 0;
	grid-template-columns: var(--gutter) 1fr var(--gutter);
	align-content: start;
	scrollbar-width: none;
	overflow-y: hidden;
}

.scroll-wizzard::-webkit-scrollbar {
	display: none;
}

.scroll-wizzard>* {
	grid-column: 2 / -2;
}

.scroll-wizzard>.full {
	grid-column: 1 / -1;
}

.hs {
	/* scrollbar-width: none; */
	/* scroll-behavior: smooth; */
	display: grid;
	grid-gap: 2vh;
	/* grid-template-columns: 0; */
	/* grid-template-rows: minmax(100%, 1fr); */
	grid-auto-flow: column;
	overflow-x: scroll;
	/* scroll-snap-type: x proximity;
	padding-bottom: calc(.75 * var(--gutter));
	margin-bottom: calc(-.25 * var(--gutter));
	margin-top: 0; */
     
}


/* @media screen and (max-width: 1181px) {
        .hs{
            margin-left: 20px;
        }
} */

.hs::-webkit-scrollbar {
	display: none;
}

/* .hs:before,
.hs:after {
	content: '';
	width: 20px;
    margin-left: 20px;
} */

ul {
	list-style: none;
	padding: 0;
}


/* .hs>li,
.item {
	scroll-snap-align: center;
	display: flex;
	flex-direction: column;
	justify-content: top;
	align-items: center;
} */

.no-scrollbar {
	scrollbar-width: none;
	margin-bottom: 0;
	padding-bottom: 0;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* global media queries */

.cursor-pointer{
    cursor: pointer;
}

@media screen and (min-width: 1181px) {
	.style-mobile {
		display: none !important;
	}
}

@media screen and (max-width: 1181px) {
	.style-desktop {
		display: none !important;
	}
}

img{
    object-fit: scale-down;
}