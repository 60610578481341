.step-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 0 10px;

	&__circle {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: var(--color-border);
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&--complete {
			background: #6BD196;
		}
	}

	&__label {
		font-weight: 600;
		font-size: 11px;
		text-align: center;
		margin-top: 10px;
		width: 78px;
	}

	&:not(:last-child) {
		&:after {
			content: "";
			height: 3px;
			width: 20px;
			background: var(--color-border);
			display: block;
			position: absolute;
			top: 16px;
			transform: translateY(-50%);
			right: -10px;
		}
	}
}