.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
	background-color: rgb(187, 107, 217);
}

.copyMeal {
	&_row {
		display: flex;
		flex-direction: column;
	}

	&_dropdown {
		margin-top: 20px;
	}

	&_action-button {
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}
}
