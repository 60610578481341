.DropdownMenu {
	overflow: hidden;

	&__trigger {
		cursor: pointer;
		z-index: 101;
	}

	&__menu {
		border-radius: 5px;
		color: var(--color-primary-orange);
		position: absolute;
		z-index: 100;
		background: var(--color-white);
		border: 1px solid var(--color-primary-orange);
		box-sizing: border-box;
		transition: all .05s linear;
		visibility: hidden;

        @media screen and (max-width: 1181px ) {
            min-width: 20vh;
        }

		&--visible {
			visibility: visible;
		}

		&--bottom100{
			bottom: 100%;
		}

		&--bottomminus100{
			bottom: -100%;
		}

		&--right0{
			right: 0;
		}

		li {
			text-align: left;
			font-size: 12px;
			display: flex;
			color: var(--color-primary-orange);
			padding: 10px 15px;
			font-weight: 500;
			width: 100%;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
            white-space: nowrap;
            
            @media screen and (max-width: 1181px ) {
                white-space: break-spaces;
            }
			&:not(:last-child) {
				border-bottom: 1px solid var(--color-primary-orange);
			}

			&:hover {
				background: var(--color-background-orange);
			}
		}
	}
}