/* colors */

:root {
	--color-white: #ffffff;
	--color-black: #000000;
	--color-dark:  #2A2B2F;
	--color-primary: #4871db;    
	--color-primary-green-light: #e1f6ea;
	--color-primary-green: #6BD196;
	--color-primary-green-dark: #4b9269;
	--color-primary-yellow:  #FCCF5A;
	--color-primary-orange: #fd6851;  
	--color-primary-red: #FF6464;
	--color-grey: #b0b4c4;
	--color-grey-dark: #353541;
	--color-grey-light: #e3e3e3;
	--color-purple: #bb6bd9;
	--color-border: #eceef4;

	--color-indicator-green-dark: #36694b;
	--color-indicator-green: #6BD196;
	--color-indicator-green-light: #97dfb6;
	--color-indicator-yellow: #FCCF5A;
	--color-indicator-orange: #f57c00;
	--color-indicator-red: #FF6464;

	--color-background-light: #FDFDFF;
	--color-background-dark: #F2F2FB;
	--color-background-orange: #fde5e1;
	--color-background-controls: #FBFBFF;
    --color-backgorund-disabled: #fafafa;

	--color-info-light: #dae3f8;
	--color-info: #4166c5;
	--color-info-dark: #24396e;

	--primary-gradient: linear-gradient(#FF7E36, #FA3A68);
	--button-gradient: linear-gradient(248.16deg, #FF7E36 -9.14%, rgba(250, 58, 104, 0.91) 113.05%);

	--container-padding-h: 120px;
	--container-padding-h-mobile: 1.7vh;

	--container-padding-v: 100px;
	--container-padding-v-mobile: 30px;
}
