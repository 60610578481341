.shared_orange {
	color: #FD6851;
}

.shared_violet {
	color: #BB6BD9;
}

.shared_header_title {
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	line-height: 39px;
	color: #353541;
}