.Modal {
	display: none;

	&--visible {
		display: block;
	}

	&__underlay {
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.7);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
	}

	&__close-icon {
		position: fixed;
		right: 20px;
		top: 20px;
		z-index: 3;
		cursor: pointer;
	}

	&__box {
        padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
		background: var(--color-white);
		position: fixed;
		z-index: 2;
		width: 100%;
		top: 70px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 100%;
		height: calc(100% - 70px);
		max-height: calc(100% - 70px);
		overflow: auto;

        @media screen and (min-width: 750px) {
            padding: var(--container-padding-v-mobile) var(--container-padding-v-mobile);
            width: 75%;
            min-height: 75%;
            height: auto;
			max-height: calc(100% - 140px);
            border: 1px solid var(--color-border);
			top: calc(50% + 35px);
			transform: translate(-50%, -50%);
			border-radius: 20px;
			&::-webkit-scrollbar-track {
				margin: 15px 0;
			}
	    }

		/* todo: move to mixin */
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--color-grey-light);
		}

		&::-webkit-scrollbar-thumb:hover {
			background: var(--color-grey);
		}
	}

	
}