#meal-details-card {

    width  : 100%;
    padding: var(--container-padding-v-mobile) var(--container-padding-h) var(--container-padding-v);

    @media screen and (max-width: 1180px) {
        padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
    }


    @media screen and (max-width: 750px) {
        background-color: var(--color-white);
    }



    .meal-details {

        &__header {
            position       : relative;
            display        : flex;
            justify-content: space-between;
            align-items    : flex-start;

            @media screen and (max-width: 750px) {
                flex-direction: column;
            }

            h3 {
                font-style   : normal;
                font-weight  : bold;
                font-size    : 20px;
                line-height  : 30px;
                display      : flex;
                align-items  : center;
                margin-top   : 10px;
                margin-bottom: 10px;
            }

            h5 {
                font-style   : normal;
                font-weight  : 500;
                font-size    : 13px;
                line-height  : 19px;
                color        : var(--color-grey);
                margin-top   : 0px;
                margin-bottom: 20px;
            }
        }


        &__container {
            background-color: var(--color-white);
            padding         : 40px 60px 50px;
            border-radius   : 10px;
            border          : 1px solid var(--color-border);

            @media screen and (max-width: 750px) {
                border-radius: 0;
                border       : none;
                padding      : 0;
            }
        }

        &__favourite {
            width          : 100%;
            display        : flex;
            align-items    : center;
            justify-content: flex-end;
            cursor         : pointer;

            @media screen and (max-width: 750px) {
                display: none;
            }
        }

        &__summary-view {
            display        : flex;
            justify-content: space-between;
            align-items    : flex-start;
            min-height     : 500px;
            padding        : 0 0 30px 0;
            border-bottom  : 1px solid var(--color-border);

            @media screen and (max-width: 1180px) {
                flex-direction: column;
            }

            @media screen and (max-width: 750px) {
                width  : calc(100% + 60px);
                margin : 0 -30px;
                padding: 0 30px 30px;
            }
        }

        &__image {
            flex               : 1;
            height             : 50vh;
            box-shadow         : 0 0 2.61795px rgba(0, 0, 0, 0.05);
            border-radius      : 8.87755px;
            background-repeat  : no-repeat;
            background-position: center;
            background-size    : cover;
            margin-right       : 15px;
            background-color: var(--color-background-dark);
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 1180px) {
                flex  : none;
                width : 100%;
                margin: 30px 0;
            }

            @media screen and (max-width: 750px) {
                margin: 0 0 30px;
            }

            img {
                width: 25%;
                opacity: 0.2;
            }

        }

        &__recipe {
            flex       : 1;
            min-width  : 500px;
            margin-left: 15px;

            @media screen and (max-width: 750px) {
                margin   : 0;
                width    : 100%;
                min-width: 300px;
            }
        }
    }

    .nutrition-fact-summary__item {

        width: 100px;

        @media screen and (max-width: 750px) {
            width: 100%;
        }
    }

    .nutrition-fact-summary.meal-details__nutrition-summary {
        background-color: var(--color-background-dark);
        border-radius   : 10px;

        @media screen and (max-width: 750px) {
            margin        : 10px -30px 30px;
            width         : calc(100% + 60px);
            border-radius : 0;
            flex-direction: row-reverse;
        }


        .nutrition-fact-summary__item--active-grey {
            background-color: var(--color-purple);

            .nutrition-fact-summary__item-value,
            .nutrition-fact-summary__item-label {
                color: var(--color-white) !important;
            }
        }
    }
}


// @media screen and (max-width: 1180px) {
//     #meal-details-card {

//         .meal-details {

//             // &__header {               
//             //     display        : flex;
//             //     justify-content: space-between;
//             //     align-items    : flex-start;
//             // }


//             &__container {
//                 background-color: var(--color-white);
//                 padding         : 40px 60px 50px;
//                 border-radius   : 10px;
//                 border          : 1px solid var(--color-border);
//             }

//             &__favourite {
//                 width          : 100%;
//                 display        : flex;
//                 align-items    : center;
//                 justify-content: flex-end;
//                 cursor         : pointer;
//             }

//             &__summary-view {
//                 flex-direction: column;
//             }

//             &__image {
//                 flex               : 1;
//                 height             : 50vh;
//                 box-shadow         : 0px 0px 2.61795px rgba(0, 0, 0, 0.05);
//                 border-radius      : 8.87755px;
//                 background-repeat  : no-repeat;
//                 background-position: center;
//                 background-size    : cover;
//                 margin-right       : 15px;
//             }

//             &__recipe {
//                 flex       : 1;
//                 min-width  : 500px;
//                 margin-left: 15px;
//             }
//         }

//         .nutrition-fact-summary.meal-details__nutrition-summary {
//             background-color: var(--color-background-dark);
//             border-radius   : 10px;

//             .nutrition-fact-summary__item--active-grey {
//                 background-color: var(--color-purple);

//                 .nutrition-fact-summary__item-value,
//                 .nutrition-fact-summary__item-label {
//                     color: var(--color-white) !important;
//                 }
//             }
//         }
//     }
// }