.Ingredient {
    margin-bottom: 13px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;

    &_row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 3;
        width: 220px;
    }

    &_switch {

        display: flex;
        align-items: center;
        font-size: 15px;

        &_left{
            margin-right: 8px
        }

        &_right{
            margin-left: 8px;
            font-style: italic;
        }

        &_popover {
            display: flex;
            position: relative;
            margin-top: 20px;

            ul {
                position: absolute;
                right: -100px;
                bottom: -55px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                background: var(--color-white);
                border: 1px solid var(--color-border);
                box-sizing: border-box;
            }

            li {
                font-size: 12px;
                display: flex;
                color: var(--color-primary-orange);
                padding: 5px 10px;
                flex-grow: 1;
                // width: 150px;
                height: 35px;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;

                &:not(:last-child) {
                    border-bottom: 1px solid var(--color-border);
                }
            }

            &_button{
                font-weight: 600;
                font-size: 12px;
                display: flex;
                color: var(--color-primary-orange);
                flex-grow: 1;
                width: 150px;
                height: 33px;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    
    &:not(:first-child) {
        border-top: 1px solid var(--color-grey-light);
    }

    &_input {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 169%;
        color: #B0B4C4;
        padding-left: 1em;
    }

    &_name {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #353541;
    margin: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
    }

    &_group{
    display: flex;
    gap: 30px;
    }

    &_remove{
            display: flex;
    flex-grow: 2;
    justify-content: flex-end;
        &_icon{
                width: 20px;
    border-radius: 20px;
            cursor: pointer;
        }
    }
}