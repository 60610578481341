.flexContainerRowActions a {
	display: flex;
}

.mainLabeStyleDivider {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	padding: 0;
	margin: 0;
	color: #888;
	display: inline-block;
}

.buttonStyleReset {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: none;
}

.day-picker {
	&__action-links {
		margin-top: 5px;
		display: flex;
		gap: 10px;
		justify-content: space-between;
	}

	&__header {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: 20px;
	}

	&__headline {
		font-size: 18px;
		color: var(--color-grey-dark);
		display: inline;
		padding: 0;
		margin: 0;

		&-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&:first-child {
			border-right: 1px solid var(--color-grey);
			padding-right: 10px;
			margin-right: 10px;
		}

		&--bold {
			font-weight: 700;
		}
	}

	@media screen and (min-width: 1181px) {
		&__header {
			margin-top: 0;
			gap: 24px;
			justify-content: left;
		}
	}
}