.TopNavUserMenu {
	position: relative;
	min-width: 175px;

	&__trigger {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		cursor: pointer;

		img {
			width: 30px;
			height: 30px;
		}
	}

	&__trigger-user {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__options {
		background: var(--color-white);
		border: 1px solid var(--color-border);
		border-radius: 5px;
		margin: 0;
		padding: 0;
		list-style-type: none;
		position: absolute;
		width: 100%;
		text-align: left;
		top: calc(100% + 5px);

		li {
			padding: 10px 15px;
			margin: 0;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 5px;

			&:hover {
				background: var(--color-background-dark);
			}
		}
	}
}