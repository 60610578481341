.landing-page-diet-types {
	padding: 75px var(--container-padding-h-mobile) 35px var(--container-padding-h-mobile);
	position: relative;
}

.landing-page-diet-types__menu-wrapper {
	margin-top: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 1181px) {
	.landing-page-diet-types {
		padding: 150px var(--container-padding-h) 70px var(--container-padding-h);
	}

	.landing-page-diet-types__menu-wrapper {
		flex-direction: row;
	}
}