.RestaurantPage{
    padding: var(--container-padding-v-mobile) var(--container-padding-h) var(--container-padding-v);

    @media screen and (max-width: 1180px) {
        padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
    }

    h5{
        margin-top: 0;
        font-weight: 500;
    }

    &_MapModalHeader{
        margin-bottom: 2vh;
        font-style   : normal;
            font-weight  : 700;
            font-size    : 17px;
            line-height  : 30px;
            display      : flex;
            align-items  : center;
    }

    &_Container{
        margin-top: 2vh;
        @media screen and (min-width: 750px) {
            background-color: var(--color-white);
            padding: 30px 40px;
            border-radius: 10px;
            margin-top: 5vh;
            border: 1px solid var(--color-border);
        }
        h3 {
            font-style   : normal;
            font-weight  : 700;
            font-size    : 17px;
            line-height  : 30px;
            display      : flex;
            align-items  : center;
        }
    }

    &_Info{
        display: flex;
        margin-top: 5vh;
        gap: 5vh;

        @media screen and (max-width: 750px) {
            margin-top: 1vh;
            flex-direction: column;
        }

        &_Carousel{
            @media screen and (min-width: 750px) {
                width: 50%;
            }
           
            &_Image{
                display: flex;
                justify-content: center;
                text-align: center;
                height: 42vh;
                img{
                    border-radius: 10px;
                }

                @media screen and (min-width: 750px) {
                    height: 50vh;
                }
            }
        }

        &_Text {
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media screen and (min-width: 750px) {
                width: 50%;
            }

            &_Wrapper{
                display: flex;
                flex-direction: column;
                a{
                    margin-top: 1vh;
                }
            }

            &_ActionButton  {
                width: 50%;
                align-self: center;
                margin-top: auto;
            }
        }
    }

    &_Meals{
        margin-top: 6vh;
        
        &_Header {
            width: 40%;
            display        : flex;
            align-items    : center;

            h3 {
                margin     : 0 10px;
            }
		}
        &_Search{
            display: flex;
            gap: 5vh;
            @media screen and (max-width: 750px) {
                flex-direction: column;
                gap: 2vh;
            }

            &_SearchBox{
                min-width: 40%;
            }
    
           h3{
            font-weight: 600;
            margin: 0 auto;
            align-self: center;
           }
        }

        &_Container{
            width: 100%;
            background-color: var(--color-background-light);
            border-left: 1px solid var(--color-border);
            border-right: 1px solid var(--color-border);
            border-bottom: 1px solid var(--color-border);
            margin: 20px 0;

            &_Item{
                
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 15vh;
                border-top: 1px solid var(--color-border);
                padding: 0 10px;
                @media screen and (min-width: 750px) {
                    padding: 0 20px;
                }

                &_Details{
                    display: flex;
                    gap: 0.8vh;
                    align-items: center;
                    font-size: 15px;
                    font-weight: 700;
                    height: 100%;
                    max-width: 30%;
                    @media screen and (min-width: 750px) {
                        gap: 3vh;
                    }
                    img{
                        max-width:100%;
                        max-height:100%;
                        padding: 1vh 0;
                        border-radius: 10px;
                    }
                }

                &_Calories{
                    font-size: 13px;
                    font-weight: 700;
                    color: var(--color-purple);
                }
            }
        }
    }

    
    
}