#ingredinet-component {

	.ingredinet-component {
		&__caption {
			display        : flex;
			align-items    : center;
			justify-content: left;
			margin         : 0;

			h3 {
				margin     : 0 10px;
				padding    : 0;
				font-size  : 20px;
				font-weight: 700;
			}
		}

		&__list {
			font-size: 14px;
		}

		&__item {
			display    : flex;
			align-items: center;
			margin     : 0;
			font-size  : 14px;
		}

		&__ingredients {
			margin: 5px 10px
		}
	}
}