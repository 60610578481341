.UserInterview {
	&__content {
		padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
        @media screen and (min-width: 1181px) {
            padding: 30px var(--container-padding-h) var(--container-padding-v);
        }
	}
    &__content-header {
        @media screen and (min-width: 1181px) {
            display: flex;
			align-items: center;
			justify-content: space-between;
        }
    }
    &__content-header-right {
        @media screen and (min-width: 1181px) {
            display: flex;
			align-items: center;
        }
    }

	&__confirmation-box {
		background: var(--color-white);
		padding: 20px var(--container-padding-h-mobile);
		border-top: 1px solid var(--color-border);
		display: flex;
		justify-content: space-between;

		&--align-center {
			justify-content: center;
		}
	}

    &__subheader {
		margin-top: 30px;
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 15px;
		color: var(--color-grey-dark);
		scroll-behavior: smooth;

		&--first-in-row {
			margin-top: 0;
		}
	}
    
    &_row{
        display: flex;
        gap: 2.5vh;
        &_columnMobile{
            @media screen and (max-width: 1181px) {
                flex-direction: column;
                gap: 0;
            }
        }
    }

	// &__scroll-wizard-item {
	// 	margin: 0 10px;

	// 	&:first-child {
	// 		margin-left: 0;
	// 	}

	// 	&:last-child {
	// 		margin-right: 0;
	// 	}
	// }

	
}