.chips-item {
	background: #FD6851;
	margin-top: 5px;
	margin-right: 5px;
	color: var(--color-white);
	border-radius: 5px;
	font-weight: 600;
	font-size: 13px;
	display: inline-flex;
	align-items: center;
	padding: 5px 10px;

	&__button {
		margin-left: 5px;
        cursor: pointer;
	}
}