.line-decor {
	width: 65px;
	height: 3px;
	background: #FD6851;
	margin-left: auto;
	margin-right: auto;
}

.line-decor.line-decor--left {
	margin: 0;
}


.FormikError{
    color: #ed2e5e;
    font-size: 12px;
    white-space: break-spaces;
    line-height: 15px;
    margin: 5px 0 0;
}