.beverage-item {
	display: flex;
	justify-content: left;
	padding-top: 20px;
	text-align: start;
	gap: 15px;

	&__header {
		font-weight: 700;
		cursor: pointer;
		margin-right: 15px;
		display: block;
	}

	&__icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__icon {
		width: 20px;
		border-radius: 20px;
	}

	&__calories-wrapper {
		margin-left: 15px;
		padding-left: 10px;
		position: relative;

		&:before {
			display: block;
			content: "";
			height: 15px;
			position: absolute;
			left: -5px;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;
			background: var(--color-grey);
		}
	}

	@media screen and (min-width: 1181px) {
		&__header {
			display: inline;
		}
	}
}