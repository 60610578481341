.DietPlanPreferences04StepForm{
    &_chips{
        margin-bottom: 5px;
        margin-top: 5px;
        &_container{
            margin-top: 15px;
            &_label{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
            }
        }
    }
}