.RestaurantDetails{
    background: var(--color-white);

    &_padding0{
        padding: 0;
    }

    &_checkboxes{
        display: flex;
        justify-content: space-between;
        margin: 2vh 0;
        flex-direction: column;
    }

    &_form{
        &_content{
            &_row{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: stretch;
                margin: 0 auto;
    
                &_item{
                    width: 100%;
                    margin-top: 20px;
                    @media screen and (min-width: 1181px) {
                        width: 32%;
                    }
                    &_full-width{
                        @media screen and (min-width: 1181px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    
        &_terms{
            width: 100%;
            display: flex;
            gap: 1vh;
            margin: 2vh 0;
            font-size: 13px;
        }
    
        &_action-button{
            margin: 2vh auto ;
            display: flex;
            justify-content: center;
        }
    }
}