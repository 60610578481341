.CheckBox {

    &_container{
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        &_input {
            cursor: pointer;
            accent-color: var(--color-purple);
            width: 20px;
            height: 20px;
            margin: 0;
            flex: none;
        }

        &_text{
            display: flex;
            gap: 1.5vh;
            &_span{
                font-size: 13px;
                color: var(--color-grey-dark);
                    &_checked{
                        font-size: 13px;
                        color: var(--color-purple);
                        font-weight: 600;
                    }
            }

            &_uncheckedIcon {
                svg{
                    fill: var(--color-grey) !important;
                }
            }
        }
    }
}
