.SideBar {
	$root: &;
	position: fixed;
	padding-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	border-left: 1px solid var(--color-border);
	background: var(--color-background-controls);
	z-index: 10;
	height: 100%;
	width: 250px;
	right: 0;
	top: 0;

	&__close-icon {
		margin-left: auto;
		margin-top: 20px;
		margin-right: 20px;
		cursor: pointer;
	}

	ul {
		list-style-type: none;
		font-weight: 500;
		color: var(--color-grey);
		text-align: right;
		margin: 0 0 25px 0;
		width: 100%;

		li {
			padding: 10px 25px;
			cursor: pointer;

			&:hover {
				color: var(--color-primary-orange);
			}
		}
	}

	&__action-buttons {
		margin-right: 25px;

		> * {
			margin-bottom: 10px;
		}
	}
}
