#diet-summary-component.summary-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 390px;
    height: 597px;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 20px; 

        >.title{
            padding: 30px 0 20px 0;
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            color: var(--color-grey-dark);
            border-bottom: 1px solid var(--color-border);
        }

        >.content{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid var(--color-border);

            img{
                width: 113px;
                height: 113px;
            }
            
            >div:nth-child(2){
                font-size: 18px;
                font-weight: 700;
                text-align: center;
                line-height: 27px;
                padding: 10px 30px
            }

            >div:nth-child(3){
               font-size: 13px;
               text-align: center;
            }
        }
        >.payment-and-plan-summary{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;           
            height: fit-content;
            background-color: #FBFBFF;
            height: 100%;
            border-radius: 0px 0px 20px 20px;
            padding-bottom: 30px;

            >div:nth-child(1){
                font-size: 26px;
                line-height: 39px;
                font-weight: 700;
                text-align: center;
                padding: 20px 0 10px;
            }
            >div:nth-child(2){
                font-size: 13px;
                text-align: center;
            }

            button{
                width: 180px;
                margin: 20px 0 0;
            }
        }

        .rounded-button{
            display: none;
        }

        .light-button{
            background: transparent;
            outline: none;
            border: none;
            height: 42px;
            margin-top: 20px;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: var(--color-primary-orange);
            cursor: pointer;
        }
}

@media screen and (max-width: 1397px) {

    #diet-summary-component.summary-wrapper{

        height: 610px;   

    }
}

@media screen and (max-width: 785px ) {

    #diet-summary-component.summary-wrapper{
            border-radius: 0px;
            width: 100%;
            border: none;
            padding: 20px;
            height: 100%;
            // min-height: 300px;

            >div:nth-child(1),
            >div:nth-child(2){
                display: none;
            }
            >div:nth-child(3){
                background-color: var(--color-white);

                button{
                    width: 100%;
                }
            }

            .rounded-button{
                display: block;
                background-color: #FF7E36;
                background: linear-gradient(267.18deg, #FF7E36 -9.14%, rgba(250, 58, 104, 0.91) 113.05%);
                transform: rotate(90deg);
                width: 24px;
                height: 24px;
                position: absolute;
                top: -12px;
                left: calc(50% - 12px);
                cursor: pointer;
                border-radius: 50%;

                &::before{
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 9px;
                    width: 8px;
                    height: 8px;
                    border-top: 2px solid var(--color-white);
                    border-left: 2px solid var(--color-white);
                    transform: rotate(315deg);
                }
            }
        }
    }

