.meal-card-wrapper {

	@media screen and (max-width: 1180px) {
		width : calc(100% - var(--container-padding-h-mobile));
		margin: 0 calc(var(--container-padding-h-mobile)/2);
	}

	.meal-card {
		background   : var(--color-white);
		border       : 1px solid var(--color-border);
		box-sizing   : border-box;
		border-radius: 10px;
		width        : 100%;

		&__header {
			padding: 20px;
		}

		&__image {
			border-radius  : 10px;
			display        : flex;
			align-items    : center;
			justify-content: center;
			height         : 225px;
			width          : 100%;
			background-size: cover;
			background-position: center;
			margin-bottom  : 10px;
			overflow       : hidden;
			padding        : 0 20px;
            cursor: pointer;
			box-shadow         : 0 0 2.61795px rgba(0, 0, 0, 0.05);
			background-color: var(--color-background-dark);

			img {
				width: 25%;
				opacity: 0.2;
			}
		}

		&__content {
			padding: 15px 20px;
		}

		&__footer {
			display        : flex;
			flex-direction : row;
			justify-content: space-between;
			align-items: center;
			padding        : 20px;
		}

		&__meal-eaten {
			display   : flex;
			position  : relative;
		}

		&__meal-eaten-button {
			font-weight: 600;
			font-size  : 12px;
			line-height: 18px;
			display    : flex;
			align-items: center;
			color      : var(--color-primary-orange);
			padding-top: 2px;
		}

		@media screen and (min-width: 1181px) {
			max-width : 100%;
			width     : 100%;
			background: var(--color-background-light);
			margin-top: 20px;

			&__header {
				padding      : 0 0 0 30px;
				border-bottom: 1px solid var(--color-border);
			}

			&__main {
				display: flex;
				gap    : 50px;
				padding: 30px 30px 0;
			}

			&__image {
				width  : 320px;
				padding: 0;
			}

			&__footer {
				padding        : 20px 30px;
				justify-content: right;
				gap            : 30px;
			}
		}
	}
}