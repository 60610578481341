#dietAndPackageTypeCard{
	width: 100%;
	margin-right: 20px;
}

.dietAndPackageTypeCard{

	&-wrapper{
		width: 100%;
		margin-right: 20px;
		height: 100%;

		.wizzard-card{
			height: 597px;
            
			&__content{
				padding: 0;
			}
		}
	}

	&-content{
		width: 100%;
		height: 100%;
		transition: all 0.5s;

		&>.diet-types{
			border-bottom: 1px solid var(--color-border);
		}
        
		&>.diet-types, 
		&>.diet-plans{
			display: flex;
			padding: var(--container-padding-v-mobile);
		} 
	}

	&__diet{
		&-type-wrapper{
			font-weight: 700;
			font-size: 18px;
			line-height: 24px;
			width: 100%;
			text-align: center;
		}
		&-plan-wrapper{   
			padding: 0 20px;
			white-space: normal;             

			&>.monthly-payment{
				font-size: 26px;
				line-height: 39px;
				width: 100%;
				text-align: center;
				font-weight: 700;
			}

			&>.plan-description{
				font-size: 13px;
				line-height: 20px;
				text-align: center;
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 1397px) {

	.dietAndPackageTypeCard{
        
		&-wrapper{
			.wizzard-card{           
				min-height: 610px;
			}
		}

		&-content{
                
			&>.diet-types, 
			&>.diet-plans{
				flex-direction: column;
			} 
		}
	}
}

@media screen and (max-width: 785px ) {

	#dietAndPackageTypeCard{
		flex-direction: column;
		min-height: 770px
	}

	.dietAndPackageTypeCard{
		&-wrapper{
			width: 100%;
			margin: 0;
    
			.wizzard-card{
				border-radius: 0;
				min-height: 770px;
                
				&__content{
					padding: unset;
					min-height: 770px
				}
			}
		}
	}
}
    
