.DayViewTabsSelector{
    display: flex;
	border-bottom: 1px solid var(--color-grey-light);
	padding: 0 var(--container-padding-h-mobile);
	background-color: var(--color-white);
    @media screen and (min-width: 1181px) {
            padding: 0 var(--container-padding-h);
    }
    &_tab{
        font-weight: 500;
        font-size: 13px;
        text-align: center;
        color: var(--color-grey);
        margin-right: 10px;
        padding-bottom: 10px;
        cursor: pointer;

        &_active{
            border-bottom: 2px solid var(--color-primary-orange);
	        color: var(--color-primary-orange);
	        font-weight: 600;
            cursor: unset;
        }
    }
}