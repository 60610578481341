#order-menu-view{
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 var(--container-padding-h) var(--container-padding-v) var(--container-padding-h);

	>div:nth-child(1){
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: var(--container-padding-v-mobile) 0;

		.stepper-wrapper{
			margin-right: 0;
		}

		h2{
			font-weight: 700;
		}
	}

	>div:nth-child(2){
		display: flex;
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 1180px) {
	#order-menu-view{
		padding: 0 var(--container-padding-h-mobile) var(--container-padding-v-mobile) var(--container-padding-h-mobile);
	}
}

@media screen and (max-width: 785px) {
	#order-menu-view{
		padding: 0;

		>div:nth-child(1),
		>div:nth-child(2){
			flex-direction: column;
		}
	}
}