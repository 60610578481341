.DropdownList{
    select {
        height: 47px;
        text-rendering: optimizeLegibility;
        border: 1px solid #ECEEF4;
        box-sizing: border-box;
        border-radius: 3px;
        width: 100%;
        max-height: 66px;
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 169%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
    }
    
    label{
        font-size: 13px;
        line-height: 169%;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
    }
    
    /* select.decorated option:hover {
        box-shadow: 0 0 10px 100px #a818a1 inset;
    } */
    
    option {
        cursor: pointer;
    }
    
    option:hover::selection {
        cursor: pointer;
        background-color:yellow;
    }
    
    option::selection {
        cursor: pointer;
        background-color:red;
    }
}