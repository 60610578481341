.customer-opinions-slider-item {}

.customer-opinions-slider-item__quote {
	font-size: 15px;
	text-align: center;
	color: var(--color-black);
	line-height: 170%;
	margin-top: 30px;
}

.customer-opinions-slider-item__author {
	font-weight: 700;
	word-spacing: 3px;
	font-size: 15px;
	text-align: center;
	color: var(--color-black);
	margin-top: 30px;
}