.TextArea {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
	&_label {
        font-size: 13px;
        margin-bottom: 5px;
    }

    &_textarea {
        padding: 10px 16px;
        display: inline-block;
        border: 1px solid var(--color-border);
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 14px;
        height: 100%;
        resize: none;
    }
}