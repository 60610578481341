.LandingPageProductPromo {
	background: var(--color-white);
    display: flex;
    padding: 0 var(--container-padding-h-mobile);
    width: 100%;

    @media screen and (min-width: 1181px) {
        flex-direction: column;
        min-width: 40%;
        padding: 30px var(--container-padding-h);
    }
}