.nutrition-fact-real-summary {
	display: flex;
	border-bottom: 1px solid var(--color-border);
	border-left: 1px solid var(--color-border);
	border-right: 1px solid var(--color-border);
	background: var(--color-background-light);

	&__item {
		padding: 5px 10px;
		text-align: center;
		width: 25%;
	}

	&__item-value {
		font-weight: 600;
		font-size: 14px;
		margin-bottom: 3px;
	}

	&__item-left-value {
		font-size: 10px;
	}

	@media screen and (min-width: 1181px) {
		border-radius: 10px;

		&__item {
			width: 100px;
		}
	}
}