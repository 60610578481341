.WizardCard {
    height: 500px;
	min-width: 325px;
	border-radius: 20px;
	border: 1px solid var(--color-border);
	background: var(--color-white);
	display: flex;
	flex-direction: column;
	font-size: 14px;
	text-align: left;

	&__header {
		padding: 30px 0 20px 30px;
		border-bottom: 1px solid var(--color-border);
		cursor: pointer;
	}

	&__headline {
		font-weight: 700;
		font-size: 20px;
		color: var(--color-grey-dark);
	}

	&__content {
		padding: 15px 20px 30px;
		overflow: auto;
        min-height: 75%;

		/* todo: move to mixin */
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--color-grey-light);
		}

		&::-webkit-scrollbar-thumb:hover {
			background: var(--color-grey);
		}
	}
    @media screen and (max-width: 1181px) {
        &:first-child{
            margin-left: 1.5vh;
         }
         &:last-child{
            margin-right: 1.5vh;
         }
     }
	@media screen and (min-width: 1181px) {
		min-width: 490px;
	}
}