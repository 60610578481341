#order-login-card{
	width: 100%;
	height: 100%;
}

#order-login-card{

	#loginCard {
		.loginCard{
			&-wrapper{
				margin-right: 20px;
			}
		}
	}
} 

@media screen and (max-width: 785px) {
	#order-login-card{

		#loginCard {
			.loginCard{
				&-wrapper{
					margin-right: 0;

					.wizzard{
						&-card {border-radius: 0;}
					}
				}
			}
		}
	} 
}