.meal-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__content-left {
		display: flex;
	}

	&__content-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
	}

	&__title {
		font-weight: 700;
		font-size: 18px;
		color: #2A2B2F;
		margin-left: 10px;
		display: inline-block;
	}

	&__favourite-wrapper {
		margin-right: 10px;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}

	@media screen and (min-width: 1181px) {
		&__content-right {
			gap: 30px;
		}

		&__favourite-wrapper {
			margin-right: 30px;
		}
	}
}