.InstagramSlider{
    padding: 35px var(--container-padding-h-mobile) 40px;
	background: var(--color-white);
    @media screen and (min-width: 1181px) {
        padding: 70px var(--container-padding-h) 80px;
    }
        &_photosWrapper{
            display: flex;
            align-items: center;
            margin-top: 50px;
            justify-content: space-between;

            &_photo{
                border-radius: 15px;
                width: 24%;
            }
            
        }
        &_photoInSlider{
            display: flex;
            justify-content: center;
            height: 42vh;
            img{
                border-radius: 15px;
            }
        }
}