.PartnershipPanel{
    padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
    @media screen and (min-width: 1181px) {
        padding: 30px var(--container-padding-h) var(--container-padding-v);
      }

    &__main {
      @media screen and (min-width: 1181px) {
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        row-gap: 30px;
      }
    }

    &__panel-left {
        @media screen and (min-width: 1181px) {
          width: calc(50%  - 15px);
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }
    
      &__panel-right {
        @media screen and (min-width: 1181px) {
          position: sticky;
          width: calc(50%  - 15px);
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }

      &__section {
        padding-bottom: 30px;
        margin-top: 20px;
        border-bottom: 1px solid var(--color-border);
    
        @media screen and (min-width: 1181px) {
          margin-top: 0;
          border: 1px solid var(--color-border);
          padding: 20px 20px 30px 20px;
          border-radius: 10px;
          background: var(--color-white);
        }
      }
    
      &__section-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 160.5%;
        letter-spacing: 0.01em;
        color: var(--color-grey-dark);
      }

      &__section-item {
        font-size: 13px;
        line-height: 170%;
        margin-top: 20px;
        color: var(--color-grey-dark);
      }

      &__status-topic{
        font-weight: 700;
        font-size: 30px;
        line-height: 160.5%;
        letter-spacing: 0.01em;
        color: var(--color-grey-dark);
        margin-top: 30px;
    }

    &_MealsContainer{
        width: 100%;
        border-radius: 10px;
        margin: 20px 0;
        

        &_Item{
            background-color: var(--color-background-light);
        border: 1px solid var(--color-border);
        border-radius: 10px;
        margin: 1vh 0;
            display: flex;
            align-items: center;
            height: 15vh;

            img{
                max-width:100%;
                max-height:100%;
                border-radius: 10px;
                cursor: pointer;
            }

            &_RemoveIcon {
                width: 20px;
                border-radius: 20px;
                cursor: pointer;
                position: absolute;
                right: 0;
                margin: 2vh;
            }


                &_Info{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 15px;
                    font-weight: 600;
                    gap: 0.1vh;
                    padding: 1.2vh;
                    width: 100%;
                    &_status{
                        color      : var(--color-purple);
                        font-size: 15px;
                        font-weight: 700;
                    }
                        &_selectedIcon {
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            background: var(--color-border);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin: 6px;
    
                            &_selected {
                                background: #6BD196;
                            }
                            
                        }

                        &_Buttons{
                            margin-top: 1vh;
                            display: flex;
                        }
                    
                }

            
        }
    }

    &_MapContainer{
        &_Buttons{
            display: flex;
            justify-content: space-between;
            margin: 1vh 0 ;
            &_Switch{
                align-items: center;
                display: flex;
                gap: 5px;
                font-weight: 600;
                font-size: 12px;
            }
        }
        &_SearchBox{
            margin-bottom: 2vh;
        }
    }
}