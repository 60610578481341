.dateRangePickerPopoverWrapper {
	opacity: 1;
	visibility: visible;
	transform: translate(0, 0);
	transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.dateRangePickerPopoverContent{
    margin-top: 4vh;
	position: absolute;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}