.exchangeMeal {
	&_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&_group {
		margin-top: 20px;
		border-top: 1px solid #ECEEF4;
		padding-bottom: 20px;
		border-bottom: 1px solid #ECEEF4;
	}

	&_text {
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 170%;
		color: #353541;
		margin: 15px;
	}

	&_meal {
		display: flex;
		flex-direction: row;
		border: 2px solid #ECEEF4;
		flex: 1;
	}

	&_meal_selected {
		border: 2px solid #BB6BD9;
		box-sizing: border-box;
	}

	&_selectedIcon {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		margin: 6px;
	}

	&_row {
		display: flex;
		margin-top: 20px;
	}

	&_removeIcon {
		display: flex;
		justify-content: flex-end;
		flex: 1;
	}

	&_remove_icon {
		width: 25px;
		border-radius: 25px;
	}

	&_action-button {
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
	}
}