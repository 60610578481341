.DayView {
	margin-bottom: 30px;

	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}

	&__diet-in-progress-info {
		margin: 10px var(--container-padding-h-mobile);
		padding: 10px;
		font-size: 13px;
		background: var(--color-info-light);
		border: 1px solid var(--color-info);
		color: var(--color-info);
		display: flex;
		align-items: center;
		gap: 10px;

		&-loader {
			border: 2px solid var(--color-background-dark); /* Light grey */
			border-top: 2px solid var(--color-info); /* Blue */
			border-radius: 50%;
			width: 20px;
			height: 20px;
			min-width: 20px;
			min-height: 20px;
			animation: spin 2s linear infinite;
		}

		@media screen and (min-width: 1181px) {
			margin: 15px var(--container-padding-h);
		}
	}

	@media screen and (min-width: 1181px) {
		margin-bottom: 50px;
	}
}
