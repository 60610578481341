
.blog{ 
    min-height: calc(100vh - 220px);
    padding: 50px var(--container-padding-h) 20px var(--container-padding-h);
    @media screen and (max-width: 1180px) {
        padding: 50px var(--container-padding-h-mobile) 20px var(--container-padding-h-mobile);
    }
    &_cards{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
        margin: 0 auto 30px;
        @media screen and (max-width: 1180px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 785px) {
            grid-template-columns: repeat(1, 1fr);
        }

       
    }
    
    article {
        @media screen and (min-width: 785px) {
            margin-bottom: 20px;
        }
        cursor: pointer;
    }

    &_article-img {
        height:  16rem;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        @media screen and (min-width: 785px) {
            margin-bottom: 2px;
        }
      }
      
    &_article-title {
        font-size: 1.3125rem;
        color: #000;
        font-weight: 500;
      }
    &_article-subtitle {
        color: #080808;
        font-size: 0.875rem;
        opacity: 0.6;
    }
}

.rc-pagination{
    text-align: center;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover,
.rc-pagination-item:focus a,
.rc-pagination-item:hover a,
.rc-pagination-item-active,
.rc-pagination-item-active a,
.rc-pagination-prev:focus .rc-pagination-item-link,
.rc-pagination-next:focus .rc-pagination-item-link,
.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link,
.rc-pagination-simple .rc-pagination-simple-pager input:hover {
    color: var(--color-primary-orange) !important;
    border-color: var(--color-primary-orange) !important;
}