.action-link {
	align-items: center;
	display: inline-flex;
	cursor: pointer;
    border: unset;
    background: unset;
}
.action-link__disabled {
	cursor: auto;
}
.action-link__label {
	font-weight: 600;
	margin-left: 7px;
	font-size: 12px;
}
.action-link__label__disabled {
    cursor: auto;
	color: #B0B4C4 !important;
}
.disableMarginLeft {
    margin-left: 0px !important;
}