.progressBar {
    height: 5px;
    background: var(--color-primary-orange);
}
.progressBar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 99;
}