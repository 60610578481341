.day-box {
	background: #FBFBFF;
	width: 50px;
	height: 35px;
	margin-top: 10px;
	margin-right: 10px;
	color: var(--color-grey-dark);
	border: 1px solid var(--color-border);
	font-weight: 600;
	font-size: 13px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border-radius: 5px;
	cursor: pointer;

	&--checked {
		background: var(--color-purple);
		color: var(--color-white);
	}

	&__number {
		background: var(--color-primary-orange);
		width: 20px;
		height: 20px;
		color: var(--color-white);
		border-radius: 50%;
		font-size: 13px;
		position: absolute;
		top: -10px;
		right: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}