.PartnershipInitial{
    background: var(--color-white);
	padding: 50px var(--container-padding-h-mobile) 35px var(--container-padding-h-mobile);
    @media screen and (min-width: 1181px) {
        padding: 50px var(--container-padding-h) 70px var(--container-padding-h);
    } 

    &_padding0{
        padding: 0;
    }

    &_checkboxes{
        display: flex;
        justify-content: space-between;
        margin: 2vh 0;
        flex-direction: column;
    }

    &_document{
        margin-bottom: 3vh;
    }

    &_form{
        &_content{

            &_row{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: stretch;
                margin: 0 auto;

                
               
    
                &_item{
                    width: 100%;
                    margin-top: 20px;
                    @media screen and (min-width: 1181px) {
                        width: 32%;
                    }
                    &_full-width{
                        @media screen and (min-width: 1181px) {
                            width: 100%;
                        }
                    }
                }

                &_Images {
                    width: 100%;


                    &_Slider{
                       
                        &_Image{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            text-align: center;
                            
                            img{
                                border-radius: 10px;
                                max-width: 80%;
                                max-height: 80vh;
                                object-fit: contain;
                                align-self: center;
                            }

                            &_Buttons{
                                display: flex;
                                justify-content: space-between;
                                margin: 1vh 10vh;
                                    &_CloseIcon {
                                        cursor: pointer;
                                        display: flex;
                                    }
                            }
                        }

                        
                    }
                }
            }
        }
    
        &_terms{
            width: 100%;
            display: flex;
            gap: 1vh;
            margin: 2vh 0;
            font-size: 13px;
        }
    
        &_action-button{
            margin: 2vh auto ;
            display: flex;
            justify-content: center;
        }
    }
}