.PaymentChannelForm{    
    padding: 30px; 

    @media screen and (max-width: 785px ) {
        .action-button {
            width: 100%;
        }
    }

    button {
        margin-top: 30px;
    }

	&__list {
		display: flex;
		flex-wrap: wrap;  
		border-top: 1px solid var(--color-border);
		border-left: 1px solid var(--color-border);  

        &__tale {
            width: 170px;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;
            border-bottom: 1px solid var(--color-border); 
            border-right: 1px solid var(--color-border); 
        }
	}

	&__consents {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 30px;
	}

	&__form-wrapper {
		display: flex;
		width: 100%;
		margin-top: 30px;

		> div:nth-child(1){
			margin-right: 15px;
		}

		> div:nth-child(2){
			margin-left: 15px;
		}

		> div:nth-child(1), >div:nth-child(2){
			width: 100%;
		}

		.textBox {
			margin-top: 8px;
		}

        @media screen and (max-width: 1397px) {
            flex-direction: column;

			> div:nth-child(1){
				margin-right: 0;
			}

			> div:nth-child(2){
				margin-left: 0;
			}
        }
	}

	&__form-item {
		margin-bottom: 15px;
	}
}

