.RestaurantMeals {
	&_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

    &_invite {
        display: flex;
        flex-direction: column;
        margin-top: 1vh;
        gap: 2vh;
    }

    &_logo {
        width: 16vh;
        border-radius: 10px;
    }

    &_section {
        display: flex;
        gap: 3vw;
        margin: 1.5vw
    }

    &_info {
        display: flex;
        flex-direction: column;

        &_name{
            font-weight: 500;
        }
    }

	&_group {
		margin-top: 20px;
		padding-bottom: 20px;
       
	}
    

	&_text {
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 170%;
		color: #353541;
		margin: 15px;
	}

	&_meal {
		display: flex;
		flex-direction: row;
		border: 2px solid #ECEEF4;
		flex: 1;
	}

	&_meal_selected {
		border: 2px solid #BB6BD9;
		box-sizing: border-box;
	}



	&_row {
		display: flex;
		margin-top: 20px;
	}



	&_action-button {
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
	}

    &_dropdown{
        margin: 1vh 0;
    }

    &_MealsContainer{
        width: 100%;
        background-color: var(--color-background-light);
        border-left: 1px solid var(--color-border);
        border-right: 1px solid var(--color-border);
        border-bottom: 1px solid var(--color-border);
        margin: 20px 0;

        &_Item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 15vh;
            border-top: 1px solid var(--color-border);
            padding: 0 10px;
            cursor: pointer;

            @media screen and (min-width: 750px) {
                padding: 0 20px;
            }

            &_Left {
                display: flex;
                gap: 0.8vh;
                align-items: center;
                font-size: 15px;
                font-weight: 700;
                height: 100%;
                max-width: 30%;
                @media screen and (min-width: 750px) {
                    gap: 3vh;
                }
                img{
                    max-width:100%;
                    max-height:100%;
                    padding: 1vh 0;
                    border-radius: 10px;
                }
            }

            &_Right {
                font-size: 13px;
                font-weight: 700;
                color: var(--color-purple);
                display: flex;
                align-items: center;
                gap: 1vh;

                @media screen and (max-width: 750px) {
                    flex-direction: column;
                }

                &_selectedIcon {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background: var(--color-border);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin: 6px;

                    &_selected {
                        background: #6BD196;
                    }

                }
            }
        }
    }


}