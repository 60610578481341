.copyDay {
	&_row {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
	}

	&_action-button {
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected { 
	background-color: rgb(187, 107, 217);
}
