.auth_wizardcard{
    height: unset !important;
}


.AuthRegister {
	max-width: 600px;

	&__form-row {
		margin-bottom: 20px;
	}

    &_terms{
        display: flex;
        // gap: 1vh;
        margin: 2vh 0;
        font-size: 13px;
    }

    
	@media screen and (min-width: 1181px) {
		&__button {
            margin-top: 1vh;
            float: left;
		}
	}
}

.AuthLogin {
	max-width: 600px;

	&__form {
		width: 100%;
	}

	&__form-row {
		margin-bottom: 20px;
	}

	&__link {
		margin-right: 0;
		margin-left: auto;
		display: table;
	}

	&__button {
		margin-top: 20px;
        align-self: start;
	}
}

.AuthResetPassword {
	max-width: 600px;

	&__form {
		width: 100%;
	}

	&__form-row {
		margin-bottom: 20px;
	}

	&__link {
		margin-right: 0;
		margin-left: auto;
		display: table;
	}

	&__button {
		margin: 40px 20px 0 0;
	}

	&__button-wrapper {
		display: flex;
	}

	@media screen and (max-width: 785px) {
		&__button-wrapper {
			flex-direction: column;
		}

		&__button {
			margin: 20px 0;
		}
	}
}

#loginCard {
	width: 100%;
	margin-right: 20px;

	.loginCard {

		&-wrapper {

			.wizzard-card {
				min-height: 597px;
				border-radius: 20px;

				&__content {
					height: auto;
					padding: 0;
				}
			}
		}

		&-content {

			.panel {
				padding: 0 30px;

				&-radio {
					height: 80px;
					border-bottom: 1px solid var(--color-border);
					display: flex;
                    gap: 2.5vh;
					align-items: center;
				}

				&-form {
					min-height: 450px;
				}
			}
		}
	}
}

@media screen and (max-width: 785px) {

	#loginCard {
		margin-right: 0;

		.loginCard {
			&-wrapper {

				.wizzard-card {
					border-radius: 0;
				}
			}

			&-content {
				.panel {
					&-radio {
						flex-direction: column;
						align-items: flex-start;
						height: 100px;
					}

					&-form {
						.action-button {
							width: 100%
						}
					}
				}
			}
		}
	}
}