.comments {
	&-item {
		width          : 100%;
		padding        : 30px;
		background     : var(--color-background-controls);
		border         : 1px solid var(--color-border);
		border-radius  : 10px;
		margin         : 10px 0;
		display        : flex;
		flex-direction : column;
		justify-content: center;
		align-items    : flex-start;
	}

	&-caption {
		display: flex;

		&__user {
			font-weight: 700;
		}

		&__date {
			color: var(--color-grey);
		}
	}

	&-content {
		&__comment {
			margin: 10px 0;
		}

		&__image {
			width        : 140px;
			border-radius: 5px;
		}
	}
}

#comments-slider {
	.comments {

		&-item {
			margin: 10px;
			width : calc(100% - 20px) !important;
		}

		&-content {
			height    : 300px;
			overflow-y: auto;
		}
	}

	@media screen and (max-width: 750px) {
		width : calc(100% + 30px);
		margin: 0 -15px;
	}
}