#auth-view {    
    min-height: calc(100vh - 220px);

	padding: var(--container-padding-v) var(--container-padding-h);
	display: flex;
}

@media screen and (max-width: 1024px) {
	#auth-view {
		padding: var(--container-padding-v) var(--container-padding-h-mobile);
	}
}

@media screen and (max-width: 785px) {
	#auth-view {
		padding: var(--container-padding-v-mobile) 0;
	}

	#auth-view #diet-summary-component {
		display: none;
	}
}