.RangePicker{
    max-width: 300px;

    &_header {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        gap: 20px;
        @media screen and (min-width: 1181px) {
            justify-content: left;
        }

        &_value {
            font-weight: bold;
            font-size  : 20px;
            display    : flex;
            align-items: center;
            text-align : center;
            color      : var(--color-grey-dark);
            @media screen and (min-width: 1181px) {
                margin: 0 25px;
            }
        }
    }

    &_picker {
        margin-top: 5px;
        display: flex;
        justify-content: center;
    }
}