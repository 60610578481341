#diet-plan-container {
	display         : flex;
	align-items     : center;
	justify-content : space-between;
	width           : 100%;
	margin          : 0;
	background-color: var(--color-white);
	padding         : 0 var(--container-padding-h);
	height          : 120px;
	border-top      : 1px solid var(--color-border);

	.leftSide {
		display    : flex;
		align-items: center;

		.order-menu {
			width: 270px;
		}

		.price {
			width        : 100%;
			font-size    : 18px;
			padding-right: 10px;
			font-weight  : 700;
			color        : var(--color-purple);
			text-align   : right;

			span {
				font-size: 40px;
			}
		}
	}

	.rightSide {
		gap            : 20px;
		display        : flex;
		align-items    : center;
		justify-content: space-between;
	}

	div:nth-child(1) {
		div {
			font-weight: 700;
			font-size  : 20px;
		}

		&>ul {
			li {
				font-size  : 13px;
				white-space: nowrap;

				&::before {
					content    : "\2022";
					color      : var(--color-purple);
					font-weight: bold;
					display    : inline-block;
					width      : 1em;
					margin-left: -1em;
				}
			}
		}
	}
}

@media screen and (max-width: 1180px) {
	#diet-plan-container {
		padding: 0 var(--container-padding-h-mobile);
	}
}

@media screen and (max-width: 1050px) {
	#diet-plan-container {
		ul {
			display: none;
		}
	}
}

@media screen and (max-width: 820px) {
	#diet-plan-container {
		justify-content: center;

		.DietPlanPrice {
			&_regular_span {
				display: none;
			}

			&_from {
				margin-right: 0;
			}
		}

		&>div:nth-child(1) {
			display: none;
		}

		.side {
			flex: auto;

			.price {
				display: none;
			}
		}

		button {
			margin-top: 10px;
			width     : 100%;
		}

		.rightSide {
			gap            : 20px;
			display        : flex;
			align-items    : center;
			justify-content: center;
		}
	}
}

@media screen and (max-width: 480px) {
	#diet-plan-container {

		.rightSide {
			width: 100%;
		}

		.DietPlanPrice {
			display: none;
		}
	}
}