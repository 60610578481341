#comments-section {

	form {
		h3 {
			font-size  : 20px;
			font-weight: 700;
			margin     : 30px 0;
		}
	}

	.comments-section {
		&__buttons {
			display        : flex;
			align-items    : center;
			justify-content: flex-end;
			margin         : 20px 0;

			@media screen and (max-width: 750px) {
				flex-direction: column;
			}

			.add-comment {
				margin-left: 20px;

				@media screen and (max-width: 750px) {
					margin: 15px 0 0;
					width : 100%;
				}
			}
		}

		&__attachment {
			box-shadow   : 0 0 2.61795px rgba(0, 0, 0, 0.05);
			border-radius: 8.87755px;
			width        : 300px;
		}
        &__closeIcon {
			cursor: pointer;
            display: flex;
		}

		&__comment-added-info {
			padding: 10px;
			font-size: 13px;
			background: var(--color-primary-green-light);
			color: var(--color-primary-green-dark);
			border: 1px solid var(--color-primary-green);
			display: flex;
			align-items: center;
			gap: 10px;
			border-radius: 5px;
		}
	}
}