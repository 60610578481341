.customProduct {
    &_section{
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 2vh;
            &_header{
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            align-items: center;
            color: #353541;
            margin-bottom: 1vh;
            }
    }

	&_header {
		display: flex;
		justify-content: space-between;
		align-items: center;
        margin-bottom: 30px;
	}

	

    &_textArea {
        margin-top: 2vh;
    }

    &_textBox {
        margin-bottom: 5px;
    }

    &_textBoxQuantity {
       display: flex;
    }

    &_switch{
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 7px;
        
    }

    &_input {
        border: unset;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        // line-height: 169%;
        color: #B0B4C4;
        padding-left: 1em;
    }
    
    &_actionButton{
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
   
    &_searchBox{
        margin: 1vh 0;
    }
    &_nutrition{
        margin-top: 7px;
    }
    &_selectedGroup{
        display: flex;
        gap: 5px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    color: var(--color-primary-orange);
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    animation: fadein 0.35s ease-in;
}


@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}