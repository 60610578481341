.UserSettings {
  padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);

  &__main {
    @media screen and (min-width: 1181px) {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      row-gap: 30px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
    color: var(--color-grey-dark);
  }

  &__panel-left {
    @media screen and (min-width: 1181px) {
      width: calc(50%  - 15px);
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__panel-right {
    @media screen and (min-width: 1181px) {
      position: sticky;
      width: calc(50%  - 15px);
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__section {
    padding-bottom: 30px;
    margin-top: 20px;
    border-bottom: 1px solid var(--color-border);

    @media screen and (min-width: 1181px) {
      margin-top: 0;
      border: 1px solid var(--color-border);
      padding: 20px 20px 30px 20px;
      border-radius: 10px;
      background: var(--color-white);
    }
  }

  &__section-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 160.5%;
    letter-spacing: 0.01em;
    color: var(--color-grey-dark);
  }

  &__section-item {
    font-size: 13px;
    line-height: 170%;
    margin-top: 20px;
    color: var(--color-grey-dark);
  }

  &__section-item-title {
    font-weight: 600;
    font-size: 13px;
    line-height: 169%;
    color: var(--color-grey-dark);
    display: flex;
    align-items: center;
  }

  &__section-item-content {
    font-size: 13px;
    margin-top: 5px;
    align-items: center;

    &--payment {
      display: flex;
      gap: 10px;
    }

    &--order {
      display: flex;
      justify-content: space-between;
    }
  }

  &__button {
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    text-align: right;
    margin-left: 20px;
    background: none;
    border: none;
    color: var(--color-purple);
  }

  &__row {
    margin-top: 20px;
    display: flex;
    gap: 4px;
  }

  &__row-item {

    &--l {
      width: 75%;
    }

    &--m {
      width: 50%;
    }

    &--s {
      width: 25%;
    }
  }

  &__order-title {
    &--active {
      font-weight: 700;

      span {
        font-weight: 400;
      }
    }

  }

  &__order-price {
    font-weight: 700;
  }

  &__action-button {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  @media screen and (min-width: 1181px) {
    padding: 30px var(--container-padding-h) var(--container-padding-v);
  }
}