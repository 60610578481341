.paymentWizardCard{
    height: unset;
}

#paymentMethodCard{
    width: 100%;
    margin-right: 20px;
    
    .paymentMethodCard{
        
        min-height: 597px;

        &-wrapper{

            height: 100%;
                        
            .wizzard-card{
                height: 100%;

                &__content{
                    height: auto;
                    padding: 0;
                }
            }
        }

        &-content{

            .payment-methods-wrapper{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 20px 10px;
            }

            .payments-details-wrapper{
                width: 100%;
                height: 100%;
                // border-top: 1px solid var(--color-border);
            }

            .payment-method:nth-child(1),
            .payment-method:nth-child(2){
                border-right: 1px solid var(--color-border);
            }
        
            .payment-method{

                display: flex;
                flex-direction: column;
                align-items: center;
                width: 120px;
                height: 100%;
                line-height: 39px;
                font-size: 13px;

                .img-wrapper{
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                input[type="radio"]:checked ~span{
                    color: var(--color-purple);
                    font-weight: 600;
                }

                input[type="radio"]:disabled ~span{
                    color: var(--color-grey);
                }

                .radio-label{
                    padding: 0 0 10px 20px;
                }
            }
        }

    } 

}

@media screen and (max-width: 785px ) {


    .paymentMethodCard{
        &-wrapper{
        
            .wizzard-card{
                border-radius: 0px;
            }
        }
    }
}