.AuthHandleResetPassword {
	max-width: 600px;

	&__form {
		width: 100%;
	}

	&__form-row {
		margin-bottom: 20px;
	}

	&__link {
		margin-right: 0;
		margin-left: auto;
		display: table;
	}

	&__button {
		margin: 40px auto 20px;
	}

	@media screen and (min-width: 400px) {
		&__button {
			margin: 40px auto 20px 0;
		}
	}
}