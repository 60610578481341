.landing-page-customer-opinions {
	padding: 40px var(--container-padding-h-mobile) 50px var(--container-padding-h-mobile);
}

.landing-page-customer-opinions__content-box {
	background: var(--color-white);
	border: 1px solid var(--color-border);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
	border-radius: 15px;
	margin: 0 auto 50px;
	padding: 30px;
	max-width: 760px;
}

@media screen and (min-width: 1181px) {
	.landing-page-customer-opinions {
		padding: 80px var(--container-padding-h) 100px var(--container-padding-h);
	}

	.landing-page-customer-opinions__content-box {
		padding: 60px 80px;
	}
}