#credit-card-form{
	max-width: 500px;
	padding: 30px;

	.title{
		font-size: 15px;
		font-weight: 600;
		line-height: 25px;
		margin-bottom: 30px;
	}

	form{

		&>div:nth-child(2){
			display: flex;
			align-items: flex-end;
			margin: 30px 0;

			&>.textBox:nth-child(1){
				margin-right: 10px;
			}

			&>.textBox:nth-child(2){
				margin-right: 20px;
			}
		}

		button{
			margin-top: 30px
		}
	}
}