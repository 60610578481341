.headline {
	text-align: center;
	color: var(--color-grey-dark);
	margin: 30px 0;

	&--left {
		text-align: left;
	}

	&__badge {
		margin-bottom: 2px;
	}

	&__text {
		font-weight: 700;
		font-size: 25px;
	}

	&__sub-headline {
		font-size: 15px;
	}
}