html,
body,
#root {
	min-height: 100%;
	height: 100%;
}
.App {
	background: #fbfbff;
	min-height: 100%;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

input {
	border-radius: 0;
}




a, a:hover {
	text-decoration: none;
}

a {
	color: var(--color-grey);
}

a:hover {
	color: var(--color-primary-orange);
}

fieldset {
    border: 0;
}
input:disabled {
    background-color: var(--color-backgorund-disabled);
}