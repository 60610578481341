.RestaurantPartner{
    h3 {
        font-style   : normal;
        font-weight  : 700;
        font-size    : 17px;
        line-height  : 30px;
        display      : flex;
        align-items  : center;
    }

    &_ActionButton{
        margin: 10vh 0;
        display: flex;
        justify-content: center;
    }
}