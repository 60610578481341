.nutrition-fact-summary {
	$root: &;
	background: var(--color-background-dark);
	border: 1px solid var(--color-border);
	overflow-y: hidden;
	white-space: nowrap;
	box-sizing: border-box;
	align-items: center;
	display: flex;
	justify-items: end;
	margin-top: 25px;
	position: relative;

	&__item {
		$item: &;
		padding: 10px 0;
		display: inline-block;
		text-align: center;
		width: 25%;

		&--active {
			background: var(--color-grey-light);

			& > * {
				color: var(--color-grey-dark) !important;
			}
		}

		&--active-grey {
			background: var(--color-background-dark);

			& > * {
				color: var(--color-grey-dark) !important;
			}
		}

		&--danger {
			& > * {
				color: #ff7878 !important;
			}
		}
	}

	&__item-value {
		font-weight: 600;
		font-size: 16px;
		color: var(--color-grey-dark);
	}

	&__item-label {
		font-size: 13px;
		color: var(--color-grey);
	}

	&--no-background {
		background: none;
		border: none;
	}

	&--no-background &__item {
		border-left: 1px solid var(--color-border);
	}
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: 1181px) {
		margin-top: 0;
		border-radius: 0 10px 0 0;

		&--with-favourite {
			border-radius: 0;
		}

		&--rounded {
			border-radius: 10px;
		}

		&__item {
			width: 100px;
		}
	}

	@keyframes slide-in {
		0% { transform: translateY(-100%); }
		25% { transform: translateY(-75%); }
		50% { transform: translateY(-50%); }
		75% { transform: translateY(-25%); }
		100% { transform: translateX(0); }
	}

	&__additional-info {
		transform: translateY(-100%);
		display: none;
		transition-duration: 5s;
		animation: slide-in .1s;

		&--visible {
			display: block;
			transform: translateY(0);
		}
	}

	&__expand-button {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: calc(100% + 5px);
	}
}