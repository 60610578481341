.Restaurants{
    min-height: calc(100vh - 420px);
    width  : 100%;
    padding: var(--container-padding-v-mobile) var(--container-padding-h) var(--container-padding-v);

    @media screen and (max-width: 1180px) {
        padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
    }

    &_container{
        margin-top: 20px;
        @media screen and (min-width: 750px) {
            background-color: var(--color-white);
            padding: 30px 40px;
            border-radius: 10px;
            border: 1px solid var(--color-border);
        }
    }

    &_offPadding{
        padding: 0
    }

    &_header{
        h3 {
            font-style   : normal;
            font-weight  : bold;
            font-size    : 20px;
            line-height  : 30px;
            display      : flex;
            align-items  : center;
            margin-top   : 10px;
            margin-bottom: 10px;
        }
    }
    &_searchSection{
        display: flex;
        justify-content: space-between;
        gap: 3vh;
        align-items: center;
        @media screen and (max-width: 660px) {
            gap: 1vh;
            flex-direction: column
        }


        &_searchBox{
            width: 100%;
            &_wrapper{
                display: flex;
                width: 100%;
                gap: 3vh;
                align-items: center;
            }
           
        }

        &_mapIcon{
            cursor: pointer;
            color: var(--color-primary-orange);
            font-size: 25px;
            transform: translateY(50%);
            @media screen and (max-width: 660px) {
                min-width: 3vh;
            }
        }
    }
}