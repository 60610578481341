.CustomMeal{

    &_Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_ActionButton {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    &_MealsContainer{

        &_Meal{
            &:not(:last-child) {
                border-bottom: 1px solid var(--color-grey-light);
            }
        }
        
    }

    &_Section {
        margin: 3vh 0;

        &_Header{
            display: flex;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 10px;
        }

        &_Name{
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            align-items: center;
            color: #353541;
            margin-left: 8px;
        }

        &_TextBox {
            margin-bottom: 10px;
        }

        &_TextArea {
            width: 100%;
        }
        &_FileUploader{
            margin-top: 1vh;
        }

        &_CloseIcon{
            cursor: pointer;
            display: flex;
        }
        &_Attachment{
            box-shadow   : 0 0 2.61795px rgba(0, 0, 0, 0.05);
            border-radius: 8.87755px;
            width        : 300px;
         }

         &_Button {
            cursor: pointer;
        }
        &_SearchBox {
            margin-bottom: 20px;
        }

        &_Ingredients{
            display: flex;
            flex-direction: column;
            gap: 5px;

            &_TextBox{
                flex-direction: row;
                gap: 2vh;
                align-items: center;
                div:first-of-type{
                    width: 20%;
                }
            }
        }
    }
    // &_name > label {
    //     font-style: normal;
    //     font-size: 15px;
    //     line-height: 27px;
    //     display: flex;
    //     align-items: center;
    //     color: #353541;
    // }
}