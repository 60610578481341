.searchBox {
	&_input-wrapper {
		position: relative;
		width: 100%;
	}

	&_input {
        height: 4vh;
		width: 100%;
		padding: 10px 35px 10px 45px;
		background: #FBFBFF;
		border-radius: 3px;
		font-size: 14px;
		text-rendering: optimizeLegibility;

		&::placeholder, &::-webkit-input-placeholder {
			color: var(--color-grey);
		}
	}

	&_icon {
		position: absolute;
        margin-left: 15px;
	}

	&_view {
        position: relative;
		display: flex;
    flex-direction: column;
    place-content: center;
	}
    
	&_label {
        font-size: 13px;
    line-height: 169%;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
	}
    
	&_result_main {
		width: 100%;
		background: #FBFBFF;
		position: relative;
	}
    
	&_result_view {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 16px;
		position: absolute;
		border: 1px solid #ECEEF4;
		border-radius: 3px;
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 14px;
		background: var(--color-white);
		z-index: 10;
	}

	&_result_lists {
		max-height: 200px;
		overflow: auto;

		& > ul {
			padding-right: 15px;
            cursor: pointer;
		}
	}

	&_favourite_list {
		border-bottom: 1px solid gray;
		padding-bottom: 10px;
		margin-right: 15px;
	}

	&_closeIcon {
        position: absolute;
        top: 14px;
        right: 10px;
	}
}
