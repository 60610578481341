.HeaderComponent {
	display       : flex;
    justify-content: space-between;
    align-items: center;
	background    : var(--color-white);
	padding       : 25px var(--container-padding-h-mobile) 20px;
    @media screen and (min-width: 1181px) {
        padding: 25px var(--container-padding-h) 20px;
    }

        &__label {
            font-weight: bold;
            font-size  : 26px;
            color      : var(--color-grey-dark);
            display    : inline;
            padding    : 0;
            margin     : 0;

            
        }

        &__settings-icon {
            margin-left: 10px;
            cursor     : pointer;
            background : none;
            border     : 0;
        }
        &__marginRight{
            margin-right: auto;
        }
}

.mainStyleDivider {
	font-size  : 26px;
	margin-left: 5px;
	color      : #8888;
	display    : inline-block;
}

.subLabeStyle {
	font-weight: bold;
	font-size  : 26px;
	line-height: 39px;
	color      : #353541;
	display    : inline;
	width      : inherit;
}

.actionLink {
	align-items : center;
	margin-left : 5px;
	margin-right: 5px;
}

.subscribeLink {
	font-weight: 500;
	font-size  : 20px;
	color      : #BB6BD9;
}

