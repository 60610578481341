.meal {
	display: flex;
	align-items: center;
	margin-top: 15px;

	&__content {
		margin-left: 10px;
		width: 100%;
	}

	&__headline {
		font-weight: 700;
		font-size: 15px;
		color: #353541;
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		max-width: 220px;
		cursor: pointer;
	}

	&__calories-wrapper {
		position: relative;
		padding-left: 10px;

		&:before {
			display: block;
			content: "";
			height: 15px;
			position: absolute;
			left: -5px;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;
			background: var(--color-grey);
		}
	}

	&__top-row {
		display: flex;
		align-items: center;
		justify-content: left;
		gap: 15px;
	}

	&__refresh-dropdown {
		position: relative;
		display: inline-block;
	}

	&__refresh-icon-wrapper {
		display: inline-block;
		margin-right: 0;
		margin-left: auto;
		cursor: pointer;
	}

	&__refresh-dropdown-content {
		position: absolute;
		min-width: 160px;
		box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
		z-index: 1;
		right: 25px;
		bottom: -20px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		background: var(--color-white);
		border: 1px solid var(--color-border);
		box-sizing: border-box;
	}

	&__refresh-dropdown-button {
		font-weight: 600;
		font-size: 12px;
		display: flex;
		color: var(--color-primary-orange);
		flex-grow: 1;
		width: 150px;
		height: 33px;
		flex-direction: column;
		justify-content: center;
	}

	&__details {
		font-size: 15px;
		line-height: 170%;
		color: var(--color-grey-dark);

		&:not(:first-child) {
			margin-left: 15px;
			padding-left: 10px;
			position: relative;

			&:before {
				display: block;
				content: "";
				height: 15px;
				position: absolute;
				left: -5px;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
				background: var(--color-grey);
			}
		}
	}

	&__favourite-icon-wrapper {
		cursor: pointer;
	}

	@media screen and (min-width: 1181px) {
		&__top-row {
			gap: 15px;
		}

    

		&__headline {
			max-width: none;
		}
	}
}