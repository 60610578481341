.datePickerPopoverWrapper {
	z-index: 10;
	transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
	position: absolute;
	top: 100%;

	&--top {
		top: unset;
		bottom: 100%;
	}
}

.datePickerPopoverContent {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}