.document {

  background: var(--color-white);
  padding: 50px var(--container-padding-h-mobile) 35px var(--container-padding-h-mobile);
  position: relative;
  word-break: break-word;
  @media screen and (min-width: 1181px) {
    padding: 50px var(--container-padding-h) 70px var(--container-padding-h);
    text-align: left;
  }

  &_img {
    border-radius: 10px;
    display: flex;
    margin: 1.5vh auto;
    border: 1px solid var(--color-border);
    max-width: 100%;
    object-fit: scale-down;
  }

  &_thumbimg {
    max-width: 33rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
  }

  &_topic {
    color: var(--color-grey-dark);
    font-size: 35px;
    font-weight: 700;
    margin-top: 30px;
  }

  &_content {
    margin-top: 20px;
    color: var(--color-black);
    line-height: 28px;
  }

  &_dietDefinition {
    @media screen and (min-width: 1181px) {
      display: flex;
      gap: 3vh;
      align-items: center;

    }

    img {
      width: 50%;
      @media screen and (max-width: 1181px) {
        width: 100%;
        margin-top: 20px;
      }
    }


  }

  &_dietDefinitions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 75px;
    padding-bottom: 25px;
    @media screen and (min-width: 1181px) {
      flex-direction: row;
    }

    &_buttonContainer {
      display: flex;
      flex-direction: column;
      gap: 3vh;
      margin-top: 5vh;
      align-items: center;
      place-content: center;
      text-align: center;
      @media screen and (min-width: 1181px) {
        flex-direction: row;
      }
    }


    &_box {
      text-align: center;
      width: 330px;
      background: var(--color-background-light);
      border: 1px solid var(--color-border);
      box-sizing: border-box;
      border-radius: 10px;
      padding-top: 130px;
      position: relative;
      min-height: 460px;
      padding-bottom: 20px;

      &:not(:first-child) {
        margin-top: 60px;
      }

      @media screen and (min-width: 1181px) {
        &:not(:first-child) {
          margin-left: 25px;
          margin-top: 0;
        }

      }

      &_img {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-size: cover;
      }

      &_headline {
        font-weight: 700;
        font-size: 18px;
        margin-top: 5px;
      }

      &_button {
        width: 200px;
        margin: 15px auto 0 auto;

        a:hover {
          color: var(--color-white);
        }
      }

      &_about {
        text-align: center;
        background: var(--color-background-light);
        box-sizing: border-box;
        padding: 15px 30px 0 30px;
        line-height: 170%;
        min-height: 205px;

        span {
          font-size: 13px;
          line-height: 170%;
          text-align: center;
          color: var(--color-grey-dark);
        }

        a {
          cursor: pointer;
          font-size: 13px;
          line-height: 170%;
          text-align: center;
          color: var(--color-grey);

          &:hover {
            text-decoration: none;
            color: var(--color-primary-orange);
          }
        }
      }
    }


  }
}
