.ConfirmationBox {
  border: 1px solid var(--color-border);
  padding: 30px;
  background: var(--color-white);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  max-width: 100%;
  text-align: center;

  &__action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 30px;

    @media screen and (max-width: 500px) {
    flex-direction: column;    }
  }
}

.confirm-box {
    position: fixed !important;
    top: 37vh !important;
    left: 0 !important;
    width: 100%;
    padding-left: var(--container-padding-v-mobile);
    padding-right: var(--container-padding-v-mobile);
}
.confirm-box__overlay{
    background-color: rgba(0,0,0,0.65) !important; 
}