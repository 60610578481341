#map
{ 
    height: 70vh !important;
    width: 100% !important;
   
    @media screen and (min-width: 750px) {
        border-radius: 10px;
        }
}

.RestaurantsMap{
    @media screen and (max-width: 750px) {
    margin-left: calc(var(--container-padding-h-mobile) * -1);
    margin-right: calc(var(--container-padding-h-mobile) * -1);
    }
   
    &_icon{
        padding-top: 55px;
        font-family: Poppins, sans-serif;
        -webkit-text-stroke: 0.1px black;
        
    }

    &_actionButton{
        margin: 20px
    }
}