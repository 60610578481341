#payment-status-container{
	height: calc(100vh - 241px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.circle{
		width: 170px;
		height: 170px;
		border-radius: 50%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
		font-size: 25px;
		font-weight: 600;
		color: var(--color-white);

		&.success{
			background-color: var(--color-primary-green);
		}

		&.failed{
			background-color: var(--color-primary-orange);
		}
	}

	button{
		margin-top: 30px;
	}
}

@media screen and (max-width: 1180px) {
	#payment-status-container{
		height: calc(100vh - 312px);
	}
}