.outlined-tale{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 4px 18px;
	border: 1px solid var(--color-border);
	margin: 6px;
	border-radius: 10px;
	background-color: #FBFBFF;
	box-sizing: border-box;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
    

	&.active{   
		background: 
			linear-gradient(#FBFBFF, #FBFBFF) padding-box,
			linear-gradient(to right, #FA3A68 , #FF7E36) border-box;
		border: 2px solid transparent;
	}
}