.landing-page-join-us {
	padding-top: 50px;
}

.landing-page-join-us__socials {
	text-align: center;
}

@media screen and (min-width: 1181px) {
	.landing-page-join-us {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 100px;
	}

	.landing-page-join-us__socials {
		margin-left: 40px;
		margin-top: 30px;
		margin-bottom: 30px;
	}
}
