.menu-subscription {
	text-align: center;
	width: 330px;
	background: var(--color-background-light);
	border: 1px solid var(--color-border);
	box-sizing: border-box;
	border-radius: 10px;
    min-height: 537px;
	padding-top: 130px;
	position: relative;
}

.menu-subscription:not(:first-child) {
	margin-top: 60px;
}

.menu-subscription__img {
	position: absolute;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
	width: 150px;
	height: 150px;
	border-radius: 50%;
	background-size: cover;
	cursor: pointer;
}

.menu-subscription__headline {
	font-weight: 700;
	font-size: 18px;
	margin-top: 30px;
}

.menu-subscription__action-button {
	width: 200px;
	margin: 15px auto 0 auto;
}

.menu-subscription__about {
	text-align: center;
	background: var(--color-background-light);
	border-top: 1px solid var(--color-border);
	box-sizing: border-box;
	/* todo: ?? */
	padding: 30px;
	margin-top: 25px;
	line-height: 170%;
}

.menu-subscription__about span {
	font-size: 13px;
	line-height: 170%;
	text-align: center;
	color: var(--color-grey-dark);
}

.menu-subscription__about a {
	font-size: 13px;
	line-height: 170%;
	text-align: center;
	color: var(--color-grey);
}

.menu-subscription__about a:hover {
	text-decoration: none;
    color: var(--color-primary-orange);
}

@media screen and (min-width: 1181px) {
	.menu-subscription:not(:first-child) {
		margin-left: 25px;
		margin-top: 0;
	}
}