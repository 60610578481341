.CustomRadio{
    // transition: all 0.2s ease;
    &_label{
        cursor: pointer;
        font-size: 13px;
        line-height: 20px;
        color: #666;
        display: flex;
        align-items: center;
        margin: 1.5vh 0;
        &_checked{
            color: #bb6bd9;
            line-height: 19px;
            font-size: 13px;
            font-weight: 600;
        }
        &_input{
            cursor: pointer;
            height: 20px;
            width: 20px;
            accent-color: #bb6bd9;
            margin: 0 6px 0 0;
        }
    }
}


// input[type="radio"]:checked::before {
// 	height: 10px;
// 	width: 10px;
// 	background: #bb6bd9;
// 	border-radius: 100px;
// 	margin-right: 15px;
// 	margin-top: 15px;
// 	margin-bottom: 15px;
// }

// input[type="radio"]:checked,
// input[type="radio"]:not(:checked) {
// 	position: absolute;
// 	left: -9999px;
// 	margin-top: 15px;
// 	margin-bottom: 15px;
// }

// input[type="radio"]:checked + label,
// input[type="radio"]:not(:checked) + label {
// 	position: relative;
// 	padding-left: 28px;
// 	cursor: pointer;
// 	line-height: 20px;
// 	display: inline-block;
// 	color: #666;
// 	margin-top: 15px;
// 	margin-bottom: 15px;
// }

// input[type="radio"]:checked + label:before,
// input[type="radio"]:not(:checked) + label:before {
// 	content: "";
// 	position: absolute;
// 	left: 0;
// 	top: 0;
// 	width: 18px;
// 	height: 18px;
// 	border: 1px solid #bb6bd9;
// 	border-radius: 100%;
// 	background: #fff;
// }

// input[type="radio"]:disabled:checked + label:before,
// input[type="radio"]:disabled:not(:checked) + label:before{
// 	border: 1px solid var(--color-grey);
// }

// input[type="radio"]:disabled:checked + label:after,
// input[type="radio"]:disabled:not(:checked) + label:after{
// 	background: var(--color-grey);
// }

// input[type="radio"]:checked + label:after,
// input[type="radio"]:not(:checked) + label:after {
// 	content: "";
// 	width: 10px;
// 	height: 10px;
// 	position: absolute;
// 	background: #bb6bd9; 
// 	top: 5px;
// 	left: 5px;
// 	border-radius: 100%;
// 	-webkit-transition: all 0.2s ease;
// 	transition: all 0.2s ease;
// }

// input[type="radio"]:not(:checked) + label:after {
// 	opacity: 0;
// 	-webkit-transform: scale(0);
// 	transform: scale(0);
// }

// input[type="radio"]:checked + label:after {
// 	opacity: 1;
// 	-webkit-transform: scale(1);
// 	transform: scale(1);
// }

// input[type="radio"]:not(:checked) + label,
// input[type="radio"]:not(:checked) + label::before {
// 	line-height: 19px;
// 	font-size: 13px;
// 	color: #353541;
// }

// input[type="radio"]:checked + label,
// input[type="radio"]:checked + label::before {
// 	color: #bb6bd9;
// 	line-height: 19px;
// 	font-size: 13px;
// 	font-weight: 600;
// }