.top-nav-mobile {
	top: 0;
	left: 0;
	width: 100%;
	position: sticky;
	min-height: 65px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--color-border);
	background: var(--color-white);
	z-index: 9;
	padding: 8px var(--container-padding-h-mobile) 5px var(--container-padding-h-mobile);
}

.top-nav {
	top: 0;
	left: 0;
	width: 100%;
	position: sticky;
	max-height: 65px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--color-border);
	background: var(--color-white);
	z-index: 9;
	font-weight: 500;
	font-size: 13px;
	text-align: center;
	color: var(--color-grey);
	box-sizing: border-box;
	padding: 8px var(--container-padding-h) 5px var(--container-padding-h);
}

.top-nav__logo {
    cursor: pointer;
	margin-right: 80px;
}

.top-nav__left {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top-nav__right {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.top-nav__left .top-nav__href {
	margin-right: 50px;
	cursor: pointer;
}

.top-nav__right .top-nav__href {
	margin-left: 25px;
	cursor: pointer;
}

.top-nav__home{
	cursor: pointer;
}

@media screen and (min-width: 1181px) {
	.top-nav {
		display: flex !important;
	}
}