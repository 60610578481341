.landing-page-header {
	background: var(--color-white);
	padding: 50px var(--container-padding-h-mobile) 35px var(--container-padding-h-mobile);
	position: relative;
	text-align: center;
}

.landing-page-header__content {
	max-width: 500px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

.landing-page-header__headline {
	color: var(--color-grey-dark);
	font-size: 35px;
	font-weight: 700;
	margin-top: 30px;
}

.landing-page-header__brief {
	font-size: 15px;
	line-height: 28px;
	display: flex;
	align-items: center;
	margin-top: 20px;
	color: var(--color-black);
}

.landing-page-header__store-buttons {
	display: flex;
	justify-content: left;
	align-content: center;
	margin-top: 30px;
}

.landing-page-header__store-buttons img {
	margin-right: 10px;
}

.landing-page-header__cover-image {
	display: none;
}
@media screen and (min-width: 1450px) {
    .landing-page-header {
		padding: 100px var(--container-padding-h) 70px var(--container-padding-h);
		text-align: left;
		height: 597px;
	}

	.landing-page-header__content {
		margin-left: 0;
		margin-right: auto;
	}

	.landing-page-header__store-buttons {
		justify-content: left;
	}
    .landing-page-header__cover-image {
		display: block;
		position: absolute;
		right: var(--container-padding-h);
		top: 0;
	}
}