.Menu{
	padding: 0 var(--container-padding-h) var(--container-padding-v);

	@media screen and (max-width: 1180px) {
		padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
	}

	&_header {
		@media screen and (min-width: 1181px) {
            gap: 0.3vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
            margin: 45px 0 25px;
		}
	}
}