.DietBalance{
    width: 100%;
	padding: 0 var(--container-padding-h) var(--container-padding-v);

	&__header{
		width: 100%;        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 25px 0;
        @media screen and (min-width: 1180px) {
            justify-content: space-between;
            padding: 30px;
            flex-direction: row;
        }

        &_rangePicker{
            @media screen and (max-width: 1180px) {
                width: 60%
            }
            
        }
		.switch-wrapper{
			display: flex;
            align-items: center;
			color: var(--color-grey);
			font-size: 13px;
			font-weight: 500;
            
			.highlighted{
				transition: all 0.5s ease 0s;
				color: var(--color-purple);
			}

			>div:nth-child(2){
				margin: 0 10px
			}
		}
	}

	&__content{
		display: flex;
		flex-wrap: wrap;
		padding: 30px;
        width: 100%;
		background-color: var(--color-white);
		border: 1px solid var(--color-border);
		min-height: 705px;
		border-radius: 20px 20px 0 0;
        
		.product{
			&__detail-wrapper{
				width: 50%;
                padding-bottom: 7px;
				&:nth-child(odd){
					padding-right: 3%;
				}
				&:nth-child(even){
					padding-left: 3%;
				}                 
			}

			&__additional-info{
				border: 1px solid var(--color-border);
				background: var(--color-background-controls);
				padding: 10px 20px;
				font-size: 13px;

				a{
					color: var(--color-purple);
					font-weight: 700;
					padding-left: 5px;
					white-space: nowrap;
				}
			}
		}
	}
}

@media screen and (max-width: 1180px) {
	.DietBalance{
        padding: var(--container-padding-v-mobile) 0;

    
		&__content{        
            border: none;
			min-height: 100%;
			height: 100%;
			border-radius: 0;    
			flex-wrap: nowrap;
			flex-direction: column;
    
			.product{
				&__detail-wrapper{
					width: 100%;
					&:nth-child(odd){
						padding-right: 0;
					}
					&:nth-child(even){
						padding-left: 0;
					}                 
				}   
			}
		}
	}
}

@media screen and (max-width: 785px) {
	.DietBalance{   

    
		&__content{
           
    
			.product{               
    
				&__additional-info{                 
    
					a{
						display: none;
					}
				}
			}
		}
	}
}
