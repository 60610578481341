.stepper-wrapper {
	display: flex;
	align-items: center;
	overflow-x: scroll;
	padding: 10px 0;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: 1181px) {
		margin-right: 40px;
	}
}