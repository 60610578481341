.DietPlanPrice {
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: baseline;
	font-weight: 700;
	font-size: 20px;
	color: var(--color-purple);

	&_from {
		font-size: 15px;
		margin-right: 7px;
	}

	&_regular {
		display: inline-block;
		position: relative;
		margin-right: 7px;
	}
	&_regular::before {
		content: '';
		position: absolute;
		left: -0.1em;
		right: -0.1em;
		top: 0.38em;
		bottom: 0.38em;
		background: linear-gradient(to left top, transparent 45.5%, currentColor 47.5%, currentColor 52.5%, transparent 54.5%);
		pointer-events: none;
	}
	&_regular_span {
		font-size: 15px;
		color:var(--color-purple);
	}
	&_span {
		margin-left: 3px;
	}
}
