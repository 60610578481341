.slider {
	width: 100%;
	padding: 45px 0;

	&__items {
		overflow: hidden;
	}

	&__triggers {
		display: flex;
		justify-content: center;
		margin-top: 3vh;
	}

	&__trigger {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		border-radius: 50%;
		margin: 0 10px;
		background: var(--color-grey);
		cursor: pointer;

		&.slider__trigger--active {
			background: var(--primary-gradient);
		}
	}
}
