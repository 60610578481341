.ProductPromoSliderItem {
    display: block;

    &__image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
        height: 400px;

        img {
            max-width: 100%;
            height: auto;
            max-height: 400px;

            @media screen and (min-width: 1181px) {
                max-height: 600px;
            }
        }

        @media screen and (min-width: 1181px) {
            width: 50%;
            height: 600px;
            padding-bottom: 0;
        }
    }

    &__content-section {
        width: 100%;
        padding-bottom: 30px;

        @media screen and (min-width: 1181px) {
            width: 50%;
            padding: 0 30px;
        }
    }

    &_content {
        text-align: center;
        color: var(--color-black);
        font-size: 15px;
        margin-top: 30px;

        p {
            margin-bottom: 40px;
        }

        @media screen and (min-width: 1181px) {
            text-align: left;
        }

        &_link {
            font-weight: 600;
            font-size: 12px;
            cursor: pointer;
            color: var(--color-purple);
        }
    }

    @media screen and (min-width: 1181px) {
        display: flex;
        align-items: center;
    }
}