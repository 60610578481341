.TrainingBar {
	padding: 10px var(--container-padding-h-mobile);
	background-color: var(--color-white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid var(--color-border);

	&__value {
		text-align: center;
	}

	&__value-number {
		font-weight: 600;
		font-size: 30px;
	}

	&__value-label {
		font-size: 13px;
	}

	&__button {
		cursor: pointer;
	}

	@media screen and (min-width: 1181px) {
		border-radius: 10px;
		gap: 30px;
	}
    @media screen and (max-width: 1181px) {
		margin-top: 25px;
	}
}
