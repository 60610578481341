.TextBox {
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	&__label {
		font-size: 13px;
		line-height: 169%;
		margin-bottom: 5px;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
	}

    &__labelRight{
        margin-left: 0.35vh;
    }
    &__inputWrapper{
        display: flex;
        width: 100%;
        border: 1px solid #ECEEF4;
        border-radius: 3px;
        line-height: 169%;
        align-items: center;
            &__input {
                cursor: unset;
                min-height: 47px;
                max-height: 66px;
                border: none;
                width: 100%;
                padding-left: 16px;
                    &__suffix{
                        padding-right: 10px;
                        color: var(--color-grey);
                        font-size: 12px;

                        
                    }
            }
            &__gray{
                background: rgb(251, 251, 255);
            }
            &__disabled{
                background-color: #fafafa;
            }
    }
	
}