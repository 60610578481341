.RestaurantsList{
    
    gap: 3vh;
    display: flex;
    flex-direction: column;
 
    h3 {
        font-style   : normal;
        font-weight  : 600;
        font-size    : 15px;
        line-height  : 30px;
        display      : flex;
        align-items  : center;
        
    }

    &_Container{
        display: flex;
        flex-direction: column;
        gap: 3vh;
    }

    &_Restaurant{
        cursor: pointer;
        border: 1px solid var(--color-border);
        border-radius: 10px;
        display: flex;
        background-color: #fbfbff;
        @media screen and (max-width: 750px) {
            background-color: var(--color-white);
        }

        &_BasicInfo{
            display: flex;
            width: 50%;
            @media screen and (max-width: 750px) {
                width: 100%;
            }
            &_Logo {
                margin: 2vh 0 2vh 2vh;
                width: 19vh;
                align-self: center;
                border-radius: 10px;
                @media screen and (max-width: 750px) {
                    width: 13vh;
                }
            }
    
            &_Info {
                display: flex;
                flex-direction: column;
                margin: 2vh;
            }

            h3 {
                font-style   : normal;
                font-weight  : 700;
                font-size    : 17px;
                line-height  : 30px;
                display      : flex;
                align-items  : center;
            }
        }
        
        &_Description{
            display: flex;
            flex-direction: column;
            width: 50%;
            margin: 2vh;
            @media screen and (max-width: 750px) {
                display: none;
            }
            h3 {
                font-style   : normal;
                font-weight  : 700;
                font-size    : 17px;
                line-height  : 30px;
                display      : flex;
                align-items  : center;
            }
        }
        
    }
}