#shopping-list {
	width  : 100%;
	padding: 0 var(--container-padding-h) var(--container-padding-v);

	@media screen and (max-width: 1180px) {
		padding: var(--container-padding-v-mobile) var(--container-padding-h-mobile);
	}
    .ActionButton {
        margin: 0 auto;
    }
	.shopping-list {
		&__wrapper {
			padding      : 25px;
			background   : var(--color-white);
			border       : 1px solid var(--color-border);
			border-radius: 20px 20px 0 0;
		}

		&__section {
			padding      : 20px;
			background   : #FBFBFF;
			border       : 1px solid var(--color-border);
			box-sizing   : border-box;
			border-radius: 10px;
            margin-bottom: 20px;

			&-header {
				font-weight  : bold;
				font-size    : 18px;
				color        : #2A2B2F;
				margin-bottom: 25px;
			}

			&-content {
				/* todo: move to mixin */
                display: flex;
                flex-direction: column;
                gap: 2vh;
				scrollbar-width: thin;

				&::-webkit-scrollbar {
					width: 4px;
				}

				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					background: var(--color-grey-light);
				}

				&::-webkit-scrollbar-thumb:hover {
					background: var(--color-grey);
				}
			}
		}

		@media screen and (min-width: 1181px) {
			&__wrapper {
                gap: 2.5vh 1.5vh;
				display        : flex;
				justify-content: center;
				flex-wrap      : wrap;
			}

			&__section {
				height      : 300px;
				width       : 24%;
                margin-bottom: unset;

				&-header {
					margin-bottom: 2vh;
				}

				&-content {
					overflow-y   : scroll;
					height       : 80%;
				}
			}
		}
	}
}