.beverages {
	background    : var(--color-white);
	border        : 1px solid var(--color-border);
	box-sizing    : border-box;
	border-radius : 10px;
	padding-bottom: 20px;

	@media screen and (max-width: 1180px) {
		width : calc(100% - var(--container-padding-h-mobile));
		margin: 0 calc(var(--container-padding-h-mobile)/2);
	}

	&__header {
		display        : flex;
		justify-content: space-between;
		align-items    : center;
		margin-bottom  : 0;
		padding        : 20px 30px;
		border-bottom  : 1px solid var(--color-border);
	}

	&__header-title {
		font-weight: bold;
		font-size  : 18px;
		color      : #2A2B2F;
		line-height: 27px;
	}

	&__header-right {
		display        : flex;
		justify-content: space-between;
		gap            : 15px;
	}

	&__content {
		padding: 0 20px;
	}

	&__items {
		overflow-y: auto;

		&-placeholder {
			margin-top: 20px;
			color: var(--color-grey);
			font-size  : 15px;
		}
	}

	&__manage-menu {
		display: flex;
		gap    : 15px;
	}

	&__manage-menu-button {
		font-weight: 600;
		line-height: 18px;
		display    : flex;
		align-items: center;
		color      : var(--color-primary-orange);
		padding    : 2px 0 0 0;
	}

	&__icon-wrapper {
		display        : flex;
		justify-content: center;
		align-items    : center;
	}

	&__edit-icon {
		padding-right : 4px;
		padding-top   : 4px;
		padding-bottom: 6px;
		color         : var(--color-primary-orange);
		font-size     : 16px;
	}

	@media screen and (min-width: 1181px) {
		max-width : 100%;
		width     : 100%;
		background: var(--color-background-light);

		&__header {
			padding: 0 0 0 30px;
		}

		&__content {
			padding: 0 30px;
		}

		&__manage-menu {
			justify-content: right;
			gap            : 30px;
		}
	}
}