.mealItem_type {
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 18px;
	color: #353541;
	display: flex;
	justify-content: space-between;
}

.mealItem_meal {    
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 16px;
	color: #000000;
}

.mealItem_header {
	display: flex;
	padding: 0 10px 0 20px;
	justify-content: space-between;
	background: #F2F2FB;
	border: 1px solid #F2F2FB;
	box-sizing: border-box;
	border-radius:10px 10px 0 0;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	color: #353541; 
}

.mealItem_icons {
	align-items: center;
	display: flex;
}

.mealItem_container {
	margin:0 0 30px 30px;
	width: 150px;
}

.mealItem_content {
	border: 1px solid #ECEEF4;
	background: white;
}

.mealItem_content_last {
	border: 1px solid #ECEEF4;
	border-radius: 0 0 10px 10px;
}

.mealMargin {
	margin: 10px;
}