.Beverages {
	&_removeIcon {
		width: 20px;
		border-radius: 20px;
		cursor: pointer;
	}

	&_header {
		margin-bottom: 13px;
		padding-top: 5px;
		display: flex;
		justify-content: space-between;
	}

	&_main {
		border-bottom: 1px solid var(--color-grey-light);
	}

	&_actionButton {
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
	}

    &_padding25{
        padding: 25px;
    }
}
