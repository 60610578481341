.checkbox-select {
	position: relative;
    cursor: pointer;

	&__trigger {
		padding: 10px 15px;
		border: 1px solid var(--color-grey);
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__dropdown {
		background: var(--color-background-controls);
		border: 1px solid var(--color-grey);
		padding: 2vh;
		position: absolute;
		width: 100%;
		left: 0;
		z-index: 999;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
        display: flex;
        flex-direction: column;
        gap: 2vh;

        

		&--top {
			bottom: 100%;
		}

		&--bottom {
			top: 100%;
		}
	}
}