.footer {
	padding: 30px var(--container-padding-h-mobile);
	background: var(--color-grey-dark);
}
.footerVersion  {
	padding-left: '31px';
	padding-right: '31px';
	background:  var(--color-grey-dark);
	display: 'flex';
	flex-direction: 'row';
	flex-wrap: 'wrap';
	justify-content: 'space-between';
	align-items: 'stretch'
} 

.footer__item {
	font-size: 13px;
	color: var(--color-white);
	margin-right: 30px;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.footer__item__row:not(:first-child) {
	margin-top: 20px;
}

.footer__item.footer__item--left .footer__item__row {
	text-align: left;
}

.footer__item.footer__item--right .footer__item__row {
	text-align: right;
}

.footer__item__row svg {
	display: inline;
}

.footer__item.footer__item--right .footer__item__row svg {
	margin-left: 40px;
}

.footer__item.footer__item--right .footer__item__row a {
	margin-left: 40px;
}

.footer__item.footer__item--left .footer__item__row svg {
	margin-right: 20px;
}

.footer__item.footer__item--left .footer__item__row a {
	margin-right: 20px;
}

.footer__item__row a {
	color: var(--color-white);
}

.footer__item__row a.footer_mail {
	color: var(--color-primary-orange);
}

@media screen and (min-width: 1181px) {
	.footer {
		padding: 30px var(--container-padding-h);
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}