.ActionButton {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--button-gradient);
    color: var(--color-white);
    outline: 0;
    border: 2px solid transparent;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    gap: 4px;
    padding: 10px 15px;
    min-width: 200px;

    &_light {
      position: relative;
      background: white;
      background-clip: padding-box;
      color: var(--color-primary-orange);

      &:hover {
        background: var(--button-gradient);
        color: var(--color-white);
        position: relative;
        background-clip: padding-box;
      }

      &:after {
        position: absolute;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        background: var(--button-gradient);
        content: '';
        z-index: -1;
      }
    }

    &_disabled {
      background: var(--color-grey);
      mix-blend-mode: normal;
      color: var(--color-white);
      cursor: default;
    }

    &--secondary {
      background: var(--color-white);
      color: var(--color-primary-orange);
      border-color: var(--color-primary-orange);
    }

    &--icon-only {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      padding: unset;
      min-width: unset;
    }

    &:hover {
      opacity: .9;
    }
  }

}