.MeasurementBar {
	width: 100%;

	&__label {
		font-size: 15px;
		font-weight: 600;
	}

	&__content {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
	}

	&__indicator-wrapper {
		width: 75%;
	}

	&__values {
		width: 20%;

		span {
			font-weight: 600;
		}
	}
}