

.shopping-list-date-range {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    &__print-button {
        text-align: center;
        @media screen and (max-width: 1181px) {
            padding-top: 20px;
        }
    }
}

@media screen and (min-width: 1181px) {
    .shopping-list-date-range {
        justify-content: left;
        padding        : 30px;
        flex-direction: row;
        &__print-button {
            margin-right: 0;
            margin-left : auto;
        }
    }
}