.weekViewHeaderTop {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	gap: 10px;
}

.weekViewHeaderTitle {
	margin: 0 0 0 30px;
}

.weekViewHeaderBottom {
	display: flex;
	margin: 15px 0 0 30px;
}

.weekViewHeaderBottomTitle {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.02em;
	margin: 0 0 0 10px
}

/* .hsWeekView {
	scrollbar-width: none;
	display: grid;
	grid-template-rows: minmax(100%, 1fr);
	grid-auto-flow: column;
	overflow-x: scroll;
	scroll-snap-type: x proximity;
	padding-bottom: calc(.75 * var(--gutter));
	margin-bottom: calc(-.25 * var(--gutter));
} */

/* .hsWeekView > li, .item {
	scroll-snap-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
} */