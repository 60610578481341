#preparation-renderer-component {
	.preparation-renderer {
		&__header {
			display        : flex;
			align-items    : center;
			justify-content: left;
			margin         : 0 0 16px 0;

			h3 {
				margin     : 0 0 0 10px;
				padding    : 0;
				font-size  : 20px;
				font-weight: 700;
			}
		}

		&__content {
			font-size: 15px;
		}
	}
}